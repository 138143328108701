import { ResponsiveTree } from '@nivo/tree'
import { recognition, RecognitionData, recognitionTree } from '../../../utils/data'
import { useAppDispatch } from '../../../hooks';
import { addAlert } from '../../../slices/alert';
import { useState } from 'react';
import { BuildingOffice2Icon, EyeSlashIcon, LinkIcon } from '@heroicons/react/24/solid';

export default function Dendrogram() {
  const dispatch = useAppDispatch();

  // Component level state
  const [activeData, setActiveData] = useState<RecognitionData | null>(null);

  // Functions
  function clickOnNode(e: any) {

    // If the node is the root node, keep going
    const isRoot = e.isRoot;
    if (isRoot) {
      dispatch(addAlert("You're at the start, go down the tree to find more detail!", "warning", 3000))
      return;
    }

    // If the node is a leaf node, gather and show use case
    const isLeaf = e.isLeaf;
    if (isLeaf) {
      const leafId = e.data.id;
      const leafData = recognition.find((r: RecognitionData) => r.id === leafId);
      setActiveData(leafData);

      const leafElement = document.getElementById("active-data") ?? null;
      leafElement?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    // Otherwise, keep traversing
    dispatch(addAlert(`You selected the "${e.id}" category, dive deeper to find something specific!`, "warning", 3000))
  }

  // Constant variables
  const recognitionData = recognitionTree;

  return (
    <>
      {/* Chart */}
      <div id="dendrogram-chart" className="lg:h-[32rem] h-[32rem] chart-container overflow-auto scrollbar-invisible">
        <div className={`lg:h-full h-[40rem] lg:w-full w-[60rem]`}>
          <ResponsiveTree
            activeNodeSize={24}
            activeLinkThickness={8}
            data={recognitionData}
            fixNodeColorAtDepth={1}
            identity="name"
            inactiveNodeSize={10}
            inactiveLinkThickness={3}
            labelsPosition="outward"
            layout="left-to-right"
            linkColor={{
              from: 'source.color',
              modifiers: [
                [
                  'opacity',
                  0.4
                ]
              ]
            }}
            linkCurve="bump"
            linkThickness={5}
            linkTooltip={(source: any, target: any) => (
              <div>
                <strong>{source.id}</strong> to <strong>{target.id}</strong>
              </div>
            )}
            linkTooltipAnchor="top"
            margin={{ top: 20, right: 250, bottom: 0, left: 90 }}
            mode="tree"
            motionConfig="wobbly"
            meshDetectionRadius={80}
            nodeSize={12}
            nodeColor={["#545263", "#66c2fc", "#c085fa", "#f0735d"]}
            onLinkMouseEnter={(e) => null}
            onLinkMouseMove={(e) => null}
            onLinkMouseLeave={(e) => null}
            onLinkClick={(e) => null}
            onNodeClick={(e) => clickOnNode(e)}
          />
        </div>
      </div>

      {/* Active data */}
      <div id="active-data">
        {activeData !== null && (
          <>
            {/* Divider & Hide button */}
            <div className="relative py-6">
              <div aria-hidden="true" className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-1 border-black" />
              </div>
              <div className="relative flex justify-center">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-dark shadow-sm ring-1 ring-inset ring-black hover:bg-beige transition ease-in-out delay-100 duration-300 hover:scale-110"
                  onClick={() => {
                    const mainElement = document.getElementById("dendrogram-chart") ?? null;
                    mainElement?.scrollIntoView({ behavior: 'smooth' });
                    setActiveData(null)
                  }}
                >
                  <EyeSlashIcon aria-hidden="true" className="-ml-1 -mr-0.5 h-5 w-5 text-dark" />
                  {`Hide "${activeData.title}"`}
                </button>
              </div>
            </div>

            {/* Information */}
            <div className="grid grid-cols-1 gap-y-4 group">

              {/* Title */}
              <span className="flex">
                <div className="h-10 w-10 mr-2 flex items-center justify-center rounded-lg bg-gradient-to-r from-transparent to-transparent group-hover:from-success group-hover:to-success-light">
                  <activeData.backgroundIcon aria-hidden="true" className={`h-6 w-6 text-dark group-hover:text-white mx-auto my-auto`} />
                </div>
                <span className={`my-auto text-dark group-hover:text-success-dark`}>
                  {activeData.title}
                </span>
              </span>

              {/* Tags */}
              <div className="flex flex-wrap gap-x-2">
                {activeData.tags.map((tag: [string, string], index: number) => (
                  <div className={`rounded-full border border-${tag[1]} text-${tag[1]} px-2 py-1 text-xs font-semibold`} key={index}>
                    {tag[0]}
                  </div>
                ))}
              </div>

              {/* Body */}
              <p className="text-sm text-secondary font-light">{activeData.longBody}</p>

              {/* Footer */}
              <figcaption className="flex flex-wrap items-center justify-between gap-x-4 gap-y-4 border-t border-dark/10 pt-4 sm:flex-nowrap">

                {/* Entity */}
                <span className="flex">
                  <BuildingOffice2Icon className="h-6 w-6 flex-none text-dark mr-2" />
                  <span className="font-semibold text-dark">{activeData.entity}</span>
                </span>

                {/* Link for more */}
                {activeData.link && activeData.linkText && (
                  <a href={activeData.link} target="_blank" rel="noreferrer" className="flex group text-dark">
                    <LinkIcon className="h-6 w-6 flex-none group-hover:text-primary mr-2" />
                    <span className="font-semibold group-hover:text-primary group-hover:underline">
                      {activeData.linkText}
                    </span>
                  </a>
                )}
              </figcaption>


            </div>
          </>
        )}
      </div>
    </>
  )
}
