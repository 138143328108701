import { BoltIcon, CubeIcon, PaintBrushIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid'

const features = [
  {
    name: "Creativity",
    description: "I believe that my purpose is to create. Whether it's a piece of art, a piece of writing, a piece of code or a piece of music. I love to create and express myself through my creations.",
    icon: PaintBrushIcon,
  },
  {
    name: 'Engineering',
    description: "I love to solve problems. I love to understand how things work. I love to build things. I love to make things better. I love to make things work.",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Design',
    description: "Design is more than just aesthetics. I enjoy applying scientific and psychological principles to designing UX's that are intuitive, engaging and delightful.",
    icon: CubeIcon,
  },
  {
    name: 'Innovation',
    description: "My fire burns strongest when I'm working with inspirational people and ideas. I love to innovate and create new things that have never been done before.",
    icon: BoltIcon,
  },
]

export default function AboutMe() {
  return (
    <div className="grid grid-cols-1 gap-y-10">
      <div className="text-left">
        <h2 className="text-xl font-medium leading-7 text-dark mb-4">About me</h2>
        <p className="text-sm font-light leading-normal text-secondary">
          Started as a creative soul loving art, design and writing. Educated as a Mathematician, learning the fundamentals about the world around us. Trained as an Engineer, to solve problems with technology. Today I merge these three domains into my every day life and work. <span className="hidden sm:block">My current areas of focus are AI and VR within Healthcare and Mental Health, but I'm intrigued by all sorts of technology use cases and love to work with likeminded people with inspired ideas.</span>
        </p>
      </div>
      <div className="">
        <dl className="grid max-w-xl lg:max-w-none grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-6 lg:gap-y-10">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-16 group">
              <dt className="text-medium font-medium leading-7 text-dark">
                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-r from-transparent to-transparent group-hover:from-primary group-hover:to-primary-light">
                  <feature.icon aria-hidden="true" className="h-6 w-6 text-dark group-hover:text-white" />
                </div>
                {feature.name}
              </dt>
              <dd className="mt-2 text-sm font-light leading-normal text-secondary">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
