import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import { useState } from 'react';

interface childNodes {
  name: string,
  children: Array<{
    name: string,
    score: number
  }>;
}

interface circlePackingData {
  name: string;
  children: childNodes[];
}

export default function CirclePacking({ data }: { data: circlePackingData }) {

  const [zoomedId, setZoomedId] = useState<string | null>(null)

  return (
    <div className="h-64 sm:h-96 md:h-96 chart-container circle-packing overflow-auto scrollbar-invisible">
      <div className={`md:h-full sm:h-96 h-64 md:w-full w-full`}>
        <ResponsiveCirclePacking
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          id="name"
          value="score"
          valueFormat=">-.0f"
          colors={["#ffffff", "#8ad0fc", "#74ed95", "#eceae2"]}
          childColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.5
              ]
            ]
          }}
          padding={8}
          enableLabels={true}
          labelsFilter={label => label.node.depth === 1}
          leavesOnly={false}
          labelsSkipRadius={10}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                2
              ]
            ]
          }}
          borderWidth={0}
          isInteractive={true}
          motionConfig="slow"
          zoomedId={zoomedId}
          onClick={node => {
            setZoomedId(zoomedId === node.id ? null : node.id)
          }}
          tooltip={(node: any) => {
            return (
              <div
                style={{
                  padding: '6px',
                  background: 'white',
                  borderRadius: '5px',
                  boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)',
                }}
              >
                <span className="text-sm text-dark">{`${node.path.length > 2 ? node.path[1] + " > " : ""}${node.data.name}`}</span>
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}
