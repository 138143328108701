import { useEffect, useState } from "react";
import AboutMe from "../about/AboutMe";
import StackedTemplateOverlapRows from "../app/StackedTemplateOverlapRows";
import Card from "../layout/Card";
import LoadingScreen from "../placeholders/LoadingScreen";
import Education from "../education/Education";
import CirclePacking from "../charts/hierarchy/CirclePacking";
import Network from "../charts/network/Network";
import Experience from "../experience/Experience";
import Stories from "../stories/Stories";
import Recognition from "../recognition/Recognition";
import Banner from "../layout/Banner";
import { expertiseData, interestsData } from "../../utils/data";
import ContactForm from "../contact/ContactForm";


export default function Home() {

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });

  // Component level state
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      {loading ? <LoadingScreen /> : (
        <StackedTemplateOverlapRows>

          <div className="grid grid-cols-1 items-start gap-y-20">

            {/* About me */}
            <section aria-labelledby="section-1-title" id="about-me">
              <h2 id="section-1-title" className="sr-only">About me</h2>
              <div className="grid grid-cols-1 gap-4">
                <Card>
                  <AboutMe />
                </Card>
              </div>
            </section>

            {/* Education */}
            <Education />

            {/* Expertise & Interests */}
            <section aria-labelledby="section-3-title">
              <h2 id="section-3-title" className="sr-only">Expertise and Interests</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

                {/* Expertise */}
                <div id="expertise">
                  <Card>
                    <div className="text-left">
                      <h2 className="text-xl font-medium leading-7 text-dark mb-4">Expertise</h2>
                      <p className="font-light text-sm leading-6 text-dark">
                        This bubble chart shows my areas of expertise. Browse and click on the bubbles to see more detailed topics of knowledge that I specialise in.
                      </p>
                      <CirclePacking data={expertiseData} />
                    </div>
                  </Card>
                </div>

                {/* Interests */}
                <div id="interests">
                  <Card>
                    <div className="text-left">
                      <h2 className="text-xl font-medium leading-7 text-dark mb-4">Interests</h2>
                      <p className="font-light text-sm leading-6 text-dark">
                        This network shows my areas of interest. Hover or click on the "nodes" and traverse the links to see related topics that I'm intrigued by.
                      </p>
                      <Network data={interestsData} />
                    </div>
                  </Card>
                </div>
              </div>
            </section>

            {/* Experience */}
            <Experience />

            {/* Stories */}
            <Stories />

            {/* Recognition */}
            {/* Update long and short form text */}
            <Recognition />

            {/* Logo cloud for client companies */}

            {/* Contact form */}
            <ContactForm />

          </div>
          <Banner />
        </StackedTemplateOverlapRows>
      )}
    </>
  );
}
