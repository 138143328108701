import Timeline from "../stats/Timeline";

export default function Education() {

  return (
    <section aria-labelledby="section-2-title" id="education">
      <h2 id="section-2-title" className="sr-only">Education</h2>
      <div className="grid grid-cols-1 gap-4">

        {/* Header */}
        <div className="text-left mb-4">
          <h2 className="text-xl font-medium leading-7 text-dark mb-4">Education</h2>
          <p className="text-sm font-light leading-normal text-dark">
            Not only do I love teaching and enabling others but I'm a lifelong learner that loves to mix formal academia and self-taught knowledge alongside practical experience.
          </p>
        </div>
        <Timeline />
      </div>
    </section>
  );
}
