const navigation = {
  website: [
    { name: "About me", href: "#about-me" },
    { name: "Education", href: "#education" },
    { name: "Expertise", href: "#expertise" },
    { name: "Interests", href: "#interests" },
    { name: "Experience", href: "#experience" },
    { name: "Stories", href: "#stories" },
    { name: "Recognition", href: "#recognition" },
    { name: "Contact", href: "#contact" },
  ],
  companies: [
    { name: "Halo", href: "https://halotechlab.com" },
    { name: "audico", href: "https://audico.co.uk" },
  ],
  apps: [
    { name: "audico Health", href: "https://health.audico.co.uk" },
    { name: "audico Hospitality", href: "https://hospitality.audico.co.uk" },
    { name: "audico Hospitality", href: "https://hospitality.audico.co.uk" },
    { name: "audico @ Home", href: "https://audico.co.uk" },
    { name: "Igloo", href: "https://entertheigloo.xyz" },
    { name: "The Launchpad", href: "https://wearethelaunchpad.com" },
  ],
  social: [
    {
      name: "X",
      href: "https://x.com/arungodwinpatel",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "https://github.com/agodwinp",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "YouTube",
    //   href: "#",
    //   icon: (props: any) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
  ],
};

export default function FooterWithMission() {
  return (
    <footer
      className="bg-gradient-to-r from-primary via-primary-light to-success background-animate border-t border-dark/10"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <a href="#start">
              <img
                className="h-8 w-8 md:h-12 md:w-12 lg:h-16 lg:w-16 rounded-full border border-2 border-dark transition ease-in-out delay-100 duration-300 hover:scale-125"
                src="/img/profile/headshot.svg"
                alt="Profile pictire"
              />
            </a>
            {/* <p className="text-sm leading-6 text-secondary">
              Making the world a better place through constructing elegant
              hierarchies.
            </p> */}
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-dark rounded-full bg-white bg-opacity-0 p-2 text-sm font-light hover:bg-opacity-10 transition ease-in-out delay-100 duration-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-medium leading-6 text-dark">
                  My website
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.website.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-dark rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-light hover:bg-opacity-10 transition ease-in-out delay-100 duration-300"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-medium leading-6 text-dark">
                  My companies
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.companies.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-light hover:bg-opacity-10 transition ease-in-out delay-100 duration-300"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-medium leading-6 text-dark">
                  My apps
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.apps.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-light hover:bg-opacity-10 transition ease-in-out delay-100 duration-300"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-dark">
            &copy; {`${new Date().getFullYear()} Arun Godwin Patel @ Halo Technology Lab Ltd. All rights reserved.`}
          </p>
        </div>
      </div>
    </footer>
  );
}
