import {
  Popover,
} from '@headlessui/react'
import FooterWithMission from '../footer/FooterWithMission'

const user = {
  name: 'Arun Godwin Patel',
  email: 'arun@halotechlab.com',
  imageUrl:
    'img/profile/headshot.svg',
}
const navigation = [
  { name: 'About me', href: '#about-me', current: true },
  { name: 'Education', href: '#education', current: false },
  { name: 'Expertise', href: '#expertise', current: false },
  { name: 'Interests', href: '#interests', current: false },
  { name: 'Experience', href: '#experience', current: false },
  { name: 'Stories', href: '#stories', current: false },
  { name: 'Recognition', href: '#recognition', current: false },
  { name: 'Contact', href: '#contact', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function StackedTemplateOverlapRows({ children }: { children: React.ReactNode }) {
  return (
    <div className="min-h-full" id="start">

      {/* Navbar */}
      <Popover as="header" className="bg-gradient-to-r from-primary via-primary-light to-success background-animate pb-24">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative flex items-center justify-center py-5 md:py-8 lg:py-12 lg:justify-between">
            {/* Name */}
            <span className="text-2xl md:text-4xl lg:text-5xl font-extrabold text-dark">Arun Godwin Patel</span>

            {/* Right section on desktop */}
            <div className="ml-4 flex items-center pr-0.5">
              {/* Profile picture */}
              <img alt="" src={user.imageUrl} className="h-8 w-8 md:h-12 md:w-12 lg:h-16 lg:w-16 rounded-full border border-2 border-dark transition ease-in-out delay-100 duration-300 hover:scale-125" />
            </div>
          </div>

          {/* Section navigation */}
          <div className="border-t border-dark border-opacity-20 py-5 overflow-scroll pl-2 pr-4">
            <div className="grid grid-cols-1 items-center gap-8">
              <div className="col-span-1">
                <nav className="flex space-x-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current ? 'text-dark' : 'text-dark',
                        'text-nowrap rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-medium hover:bg-opacity-10 transition ease-in-out delay-100 duration-300 hover:scale-110',
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Popover>

      {/* Page content */}
      <main className="-mt-24 pb-8 mb-24">
        {/* <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 h-screen overflow-scroll scrollbar-invisible"> */}
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Arun Godwin Patel website</h1>
          {children}
        </div>
      </main>

      {/* Footer */}
      <FooterWithMission />
    </div>

  )
}
