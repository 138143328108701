export function getColour(text: string | undefined | null) {
  if (text === "placeholder") return "#e8e8e8";
  if (text === "Volunteering") return "info";
  if (text === "STEM") return "primary";
  if (text === "Teaching") return "success";
  if (text === "Software Engineering") return "dark";
  if (text === "Data Science") return "primary";
  if (text === "Machine Learning") return "warning";
  if (text === "Presales") return "danger";
  if (text === "AI") return "info";
  if (text === "R&D") return "warning";
  if (text === "Full Stack Dev") return "dark";
  if (text === "Advisory") return "primary";
  if (text === "Public Speaking") return "success";
  if (text === "Healthcare") return "primary";
  if (text === "Hospitality") return "danger";
  if (text === "Mental Health") return "info";
  return "#e5e7eb";
}

export function getTextColour(text: string | undefined) {
  if (text === "placeholder") return "flint";
  return "flint";
}

export function getColours(text: string | undefined) {
  if (text === "donut-chart")
    return [
      "rgb(96 165 250)",
      "rgb(147 197 253)",
      "rgb(191 219 254)",
      "rgb(153 246 228)",
      "rgb(94 234 212)",
      "rgb(110 231 183)",
      "rgb(52 211 153)",
      "rgb(16 185 129)",
    ];
  if (text === "bar-chart") return ["#A4A2ED"];
  if (text === "bar-chart-stacked") return ["#A4A2ED", "#e5e7eb"];
  if (text === "radial-chart") return ["#F48F44", "#FFB76C", "#F8E165"];
  if (text === "pie-chart") return ["#61DF94", "#FF7878", "#DADADA"];
  if (text === "circle-packing") return ["#ffffff", "#87DFF6", "#F0F8FF"];
  if (text === "sentiment-chart") return ["#FF7878", "#DADADA", "#61DF94"];
  if (text === "radar-sentiment-chart")
    return ["#61DF94", "#FF7878", "#DADADA"];
  if (text === "check-in-chart") return ["#7977D4", "#87DFF6", "#FA99CE"];
  if (text === "swarm-chart") return ["#61DF94", "#FF7878"];
  if (text === "waffle-chart") return ["#87DFF6", "#e5e7eb"];
  return [
    "rgb(96 165 250)",
    "rgb(147 197 253)",
    "rgb(191 219 254)",
    "rgb(153 246 228)",
    "rgb(94 234 212)",
    "rgb(110 231 183)",
    "rgb(52 211 153)",
    "rgb(16 185 129)",
  ];
}

export function getThemeColours() {

  // Returns an array of [background, text] colours
  return [
    ["#c085fa", "info", "#ffffff", "white",],
    ["#66c2fc", "primary", "#ffffff", "white"],
    ["#fbbf5d", "warning", "#545263", "dark"],
    ["#54e67c", "success", "#545263", "dark"],
    ["#f0735d", "danger", "#ffffff", "white"],
    // ["#ffffff", "white", "#545263", "dark"],
    // ["#000000", "black", "#ffffff", "white"],
    ["#545263", "dark", "#ffffff", "white"],
    // ["#c4c4c4", "light", "#545263", "dark"],
    // ["#888888", "secondary", "#545263", "dark"],
  ]
}
