interface CardProps {
  colour?: string;
  children: React.ReactNode;
  className?: string;
  paddingX?: number;
  paddingY?: number;
}

export default function Card({
  colour = "white",
  children = "",
  className,
  paddingX = 6,
  paddingY = 6,
}: CardProps) {
  return (
    <div
      className={`bg-${colour} rounded-lg px-${paddingX} py-${paddingY} overflow-x-auto shadow scrollbar-invisible border border-2 border-dark ${className}`}
    >
      {children}
    </div>
  );
}