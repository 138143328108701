import { useState } from "react";
import { getColour } from '../../utils/colours'
import Card from "./Card";
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  FlagIcon,
  GlobeAsiaAustraliaIcon,
  GlobeEuropeAfricaIcon,
  LinkIcon,
  XMarkIcon
} from "@heroicons/react/24/solid";
import { ExperienceData } from "../../utils/data";


export default function StackedList({ data }: { data: Array<ExperienceData> }) {

  // Component level state
  const [activeExperienceId, setActiveExperienceId] = useState<string>("");

  // Functions
  function openExperience(e: any, objId: string) {
    e.preventDefault();
    if (activeExperienceId === objId) {
      setActiveExperienceId("")
    } else {
      setActiveExperienceId(objId);
    }
  }

  return (
    <ul className="divide-y divide-dark/20">
      {data.map((obj: ExperienceData) => {
        return (
          <div key={obj.id} className="grid grid-cols-1">

            {/* Default visible information */}
            <li key={obj.id} className="flex items-center justify-between gap-x-6 py-5">

              {/* Main details */}
              <div className="min-w-0">

                {/* Header */}
                <div className="flex items-start gap-x-3">
                  <p className="text-dark text-base font-medium leading-6">{obj.company}</p>
                  {obj.badges.map((badge: string, index: number) => {
                    const chosenColour = getColour(badge);
                    return (
                      <span
                        key={`badge-${index}`}
                        className={`hidden sm:block ring-${chosenColour} bg-${chosenColour}/10 text-${chosenColour} mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset`}
                      >
                        {badge}
                      </span>
                    )
                  })}
                </div>
                <div className="flex flex-wrap gap-2 sm:hidden block">
                  {obj.badges.map((badge: string, index: number) => {
                    const chosenColour = getColour(badge);
                    return (
                      <span
                        key={`badge-${index}`}
                        className={`ring-${chosenColour} bg-${chosenColour}/10 text-${chosenColour} mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset`}
                      >
                        {badge}
                      </span>
                    )
                  })
                  }
                </div>

                {/* Metadata */}
                <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-secondary font-light my-auto">
                  <span className="flex">
                    <CalendarDaysIcon className="h-6 w-6 text-secondary" />
                    <p className="ml-2 whitespace-nowrap my-auto">
                      {obj.date}
                    </p>
                  </span>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <span className="flex">
                    {obj.location === "Global" ? <GlobeAsiaAustraliaIcon className="h-6 w-6 text-secondary" /> : <GlobeEuropeAfricaIcon className="h-6 w-6 text-secondary" />}
                    <p className="ml-2 whitespace-nowrap my-auto">
                      {obj.location}
                    </p>
                  </span>
                </div>
              </div>

              {/* Learn more button */}
              <div className="flex flex-none items-center gap-x-4">
                <button
                  onClick={(e) => openExperience(e, obj.id)}
                  className={`flex rounded-md bg-${obj.id === activeExperienceId ? "dark" : "white"} px-2 py-1 sm:px-2.5 sm:py-1.5 text-sm font-semibold text-${obj.id === activeExperienceId ? "white" : "dark"} shadow-sm ring-2 ring-inset ring-${obj.id === activeExperienceId ? "white" : "dark"} transition ease-in-out delay-100 duration-300 hover:scale-110`}
                >
                  <span className="hidden sm:block mr-2 my-auto">
                    {obj.id === activeExperienceId ? `Hide ${obj.company}` : `Learn more about ${obj.company}`}<span className="sr-only">{obj.id === activeExperienceId ? `Hide ${obj.company}` : `Learn more about ${obj.company}`}</span>
                  </span>
                  <span className="sm:hidden block mr-1 my-auto text-xs">
                    {obj.id === activeExperienceId ? `Hide` : `See more`}<span className="sr-only">{obj.id === activeExperienceId ? `Hide ${obj.company}` : `See more about ${obj.company}`}</span>
                  </span>
                  {obj.id === activeExperienceId ? <XMarkIcon className={`sm:h-6 sm:w-6 h-5 w-5 text-${obj.id === activeExperienceId ? "white" : "dark"}`} /> : <ChevronDownIcon className={`sm:h-6 sm:w-6 h-5 w-5 text-${obj.id === activeExperienceId ? "white" : "dark"}`} />}
                </button>
              </div>
            </li>

            {/* When selected, show the roles */}
            {activeExperienceId === obj.id && (
              <div className="">
                <p className="text-sm text-dark font-light">{obj.description}</p>
                <div className="flex overflow-x-auto w-full gap-x-8 py-6">
                  {obj.roles.map((role: any, roleIdx: number) => {
                    return (
                      <Card key={`${role.title}`} className="flex-shrink-0 text-left max-w-72 md:max-w-sm lg:max-w-lg group h-fit">

                        {/* Title */}
                        <span className="flex mb-2">
                          {role.type === "role" ? (
                            <BriefcaseIcon className="h-6 w-6 text-dark group-hover:text-primary" />
                          ) : (
                            <ClipboardDocumentListIcon className="h-6 w-6 text-dark group-hover:text-primary" />
                          )}
                          <h2 className="ml-2 text-dark text-base font-medium my-auto group-hover:text-primary">{role.title} ({role.location})</h2>
                        </span>

                        {/* Dates */}
                        <span className="flex">
                          <FlagIcon className="h-6 w-6 text-dark group-hover:text-danger" />
                          <p className="ml-2 text-dark text-sm font-medium my-auto group-hover:text-danger">{role.date}</p>
                        </span>

                        {/* Description */}
                        <p className="mt-2 text-secondary text-sm font-light">{role.description}</p>

                        {/* Responsibilities */}
                        {role.responsibilities.length > 0 && (
                          <div className="mt-2">
                            <ul className="ml-3 list-disc list-outside text-secondary text-sm font-light">
                              {role.responsibilities.map((resp: string, respIdx: number) => {
                                return (
                                  <li key={`responsibility-${respIdx}`}>{resp}</li>
                                )
                              })}
                            </ul>
                          </div>
                        )}

                        {/* Link */}
                        {role.link && (
                          <div className="flex border-t border-dark/20 mt-4 text-center justify-center" >
                            <span className="flex justify-center mt-6 group">
                              <LinkIcon className="h-5 w-5 text-dark group-hover:text-primary mr-2" />
                              <a
                                href={role.link}
                                target="_blank"
                                rel="noreferrer"
                                className="my-auto text-dark text-sm font-medium group-hover:underline group-hover:text-primary"
                              >
                                {role.linkText}
                              </a>
                            </span>
                          </div>
                        )}
                      </Card>
                    )
                  })}
                </div>
              </div>
            )}

          </div>
        )
      })}
    </ul>
  )
}
