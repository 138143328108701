import { ResponsiveNetwork } from '@nivo/network'

interface childNodes {
  id: string,
  height: number,
  size: number,
  color: string
}

interface childLinks {
  source: string,
  target: string,
  distance: number,
}

interface networkData {
  nodes: childNodes[];
  links: childLinks[];
}

export default function Network({ data }: { data: networkData }) {

  return (
    <div className="h-96 sm:h-96 md:h-96 chart-container overflow-auto scrollbar-invisible">
      <div className={`md:h-full sm:h-96 h-[32rem] md:w-full w-96`}>
        <ResponsiveNetwork
          activeNodeSize={n => 4 * n.size}
          data={data}
          centeringStrength={0.1}
          distanceMin={1}
          distanceMax={100000}
          inactiveNodeSize={n => n.size * 2}
          iterations={250}
          linkBlendMode="multiply"
          linkColor={{
            from: 'target.color', modifiers: [
              [
                'darker',
                0.8
              ]
            ]
          }}
          linkDistance={e => e.distance}
          linkThickness={n => 2 + 2 * n.target.data.height}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          motionConfig="wobbly"
          nodeBorderWidth={0}
          nodeColor={e => e.color}
          nodeSize={n => n.size * 3}
          repulsivity={6}
        />
      </div>
    </div>
  )
}
