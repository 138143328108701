import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeAlert } from "../../slices/alert";

export default function Alert({
  id,
  status,
  message,
  timeout,
}: {
  id: string;
  status: string;
  message: string;
  timeout: number;
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);

  function getTextColour() {
    if (status === "warning") return "black";
    return "white";
  }

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false);
    }, timeout);

    return () => {
      clearTimeout(timeId);
    };
  }, [timeout]);

  if (!show) {
    return null;
  }

  function deleteAlert() {
    dispatch(removeAlert(id));
    setShow(false);
  }

  return (
    <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-[100]" key={id}>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className={`rounded-lg bg-${status} p-2 shadow-lg sm:p-`}>
          <div className="flex flex-wrap items-center justify-between">

            {/* Message */}
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-transparent p-2">
                <MegaphoneIcon
                  className={`h-6 w-6 text-${getTextColour()}`}
                  aria-hidden="true"
                />
              </span>
              <p
                className={`ml-3 font-medium text-${getTextColour()}`}
              >
                <span className="md:hidden">{message}</span>
                <span className="hidden md:inline">{message}</span>
              </p>
            </div>

            {/* Dismiss button */}
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className={`-mr-1 flex rounded-md p-2 hover:bg-slate-100/10 focus:outline-none focus:ring-2 focus:ring-${getTextColour()}`}
                onClick={(e: any) => deleteAlert()}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon
                  className={`h-6 w-6 text-${getTextColour()}`}
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
