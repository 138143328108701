import { AcademicCapIcon, VideoCameraIcon, BoltIcon, BriefcaseIcon, CheckBadgeIcon, HeartIcon, LightBulbIcon, PresentationChartLineIcon, TrophyIcon } from "@heroicons/react/24/solid";

// EXPERTISE
export const expertiseData = {
  name: "Expertise",
  children: [
    {
      "name": "Frontend",
      "children": [
        {
          "name": "Javascript",
          "score": 20
        },
        {
          "name": "Typescript",
          "score": 20
        },
        {
          "name": "CSS",
          "score": 20,
          "children": [
            {
              "name": "Tailwind",
              "score": 10
            },
            {
              "name": "Bootstrap",
              "score": 10
            },
          ]
        },
        {
          "name": "HTML",
          "score": 20
        },
        {
          "name": "AJAX",
          "score": 20
        },
        {
          "name": "JQuery",
          "score": 20
        },
      ]
    },
    {
      "name": "Mobile",
      "children": [
        {
          "name": "Dart",
          "score": 15
        },
        {
          "name": "React Native",
          "score": 15
        },
        {
          "name": "Android Studio",
          "score": 15
        }
      ]
    },
    {
      "name": "Backend",
      "children": [
        {
          "name": "Python",
          "score": 30
        },
        {
          "name": "Node JS",
          "score": 30
        },
        {
          "name": "C++",
          "score": 30
        },
        {
          "name": "C#",
          "score": 30
        },
      ]
    },
    {
      "name": "Database",
      "children": [
        {
          "name": "MySQL",
          "score": 30
        },
        {
          "name": "PostgreSQL",
          "score": 30
        },
        {
          "name": "MongoDB",
          "score": 30
        },
        {
          "name": "Neo4j",
          "score": 30
        },
      ]
    },
    {
      "name": "Statistical Programming",
      "children": [
        {
          "name": "R",
          "score": 15
        },
        {
          "name": "Prolog",
          "score": 15
        },
        {
          "name": "MATLAB",
          "score": 15
        },
      ]
    },
    {
      "name": "AI",
      "children": [
        {
          "name": "TensorFlow",
          "score": 8
        },
        {
          "name": "Keras",
          "score": 8
        },
        {
          "name": "PyTorch",
          "score": 8
        },
        {
          "name": "Caffe",
          "score": 8
        },
        {
          "name": "Scikit-Learn",
          "score": 8
        },
        {
          "name": "SAP HANA",
          "score": 8
        },
        {
          "name": "NLTK",
          "score": 8
        },
        {
          "name": "SpaCy",
          "score": 8
        },
        {
          "name": "HuggingFace",
          "score": 8
        },
        {
          "name": "OpenCV",
          "score": 8
        },
        {
          "name": "OpenAI",
          "score": 8
        },
        {
          "name": "Anthropic",
          "score": 8
        },
        {
          "name": "Gemini",
          "score": 8
        },
        {
          "name": "Pandas",
          "score": 8
        },
        {
          "name": "NumPy",
          "score": 8
        },
        {
          "name": "Jupyter",
          "score": 8
        },
        {
          "name": "Whisper",
          "score": 8
        },
        {
          "name": "Alexa Skills Kit",
          "score": 8
        },
      ]
    },
    {
      "name": "Web frameworks",
      "children": [
        {
          "name": "Redux",
          "score": 35
        },
        {
          "name": "React",
          "score": 35
        },
        {
          "name": "Web Extensions",
          "score": 35
        },
      ]
    },
    {
      "name": "Virtual Reality",
      "children": [
        {
          "name": "Unity",
          "score": 25
        },
        {
          "name": "Unreal",
          "score": 25
        },
        {
          "name": "Blender",
          "score": 25
        }
      ]
    },
    {
      "name": "Cloud",
      "children": [
        {
          "name": "AWS",
          "score": 25
        },
        {
          "name": "Azure",
          "score": 25
        },
        {
          "name": "GCP",
          "score": 25
        },
        {
          "name": "Digital Ocean",
          "score": 25
        },
        {
          "name": "Heroku",
          "score": 25
        }
      ]
    },
    {
      "name": "Algorithms",
      "children": [
        {
          "name": "Speech",
          "score": 1,
          "children": [
            {
              "name": "Text to Speech",
              "score": 1
            },
            {
              "name": "Speech to Text",
              "score": 1
            }
          ]
        },
        {
          "name": "Image",
          "score": 1,
          "children": [
            {
              "name": "Bag of Visual Words",
              "score": 1
            },
            {
              "name": "Keypoint Detection",
              "score": 1
            },
            {
              "name": "Text to Image",
              "score": 1
            },
            {
              "name": "Image to Text",
              "score": 1
            },
          ]
        },
        {
          "name": "Video",
          "score": 1,
          "children": [
            {
              "name": "Text to Video",
              "score": 1
            },
          ]
        },
        {
          "name": "Regression",
          "score": 1,
          "children": [
            {
              "name": "OLS",
              "score": 1
            },
            {
              "name": "Linear Regression",
              "score": 1
            },
            {
              "name": "Logistic Regression",
              "score": 1
            },
            {
              "name": "Ridge Regression",
              "score": 1
            },
            {
              "name": "Multi-Output Regression",
              "score": 1
            },
            {
              "name": "LASSO",
              "score": 1
            },
            {
              "name": "Elastic Net",
              "score": 1
            },
          ]
        },
        {
          "name": "Instance Based",
          "score": 1,
          "children": [
            {
              "name": "Nearest Neighbours",
              "score": 1
            },
            {
              "name": "Support Vector Machine",
              "score": 1
            },
          ]
        },
        {
          "name": "Trees",
          "score": 1,
          "children": [
            {
              "name": "CART",
              "score": 1
            },
            {
              "name": "CHAID",
              "score": 1
            },
            {
              "name": "Decision Tree",
              "score": 1
            },
          ]
        },
        {
          "name": "Bayesian",
          "score": 1,
          "children": [
            {
              "name": "Naïve Bayes",
              "score": 1
            },
            {
              "name": "Gaussian Naïve Bayes",
              "score": 1
            },
            {
              "name": "Bayesian Knowledge Tracing",
              "score": 1
            },
          ]
        },
        {
          "name": "Clustering",
          "score": 1,
          "children": [
            {
              "name": "K-Means",
              "score": 1
            },
            {
              "name": "Hierarchical Clustering",
              "score": 1
            },
            {
              "name": "Agglomerative Clustering",
              "score": 1
            },
          ]
        },
        {
          "name": "Association Rules",
          "score": 1,
          "children": [
            {
              "name": "Apriori",
              "score": 1
            },
          ]
        },
        {
          "name": "Graph",
          "score": 1,
          "children": [
            {
              "name": "Social Network Analysis",
              "score": 1
            },
            {
              "name": "Beam Search",
              "score": 1
            },
            {
              "name": "A*",
              "score": 1
            },
            {
              "name": "Depth First",
              "score": 1
            },
            {
              "name": "Breadth First",
              "score": 1
            },
            {
              "name": "Best First",
              "score": 1
            },
          ]
        },
        {
          "name": "Neural Networks",
          "score": 1,
          "children": [
            {
              "name": "Perceptron",
              "score": 1
            },
            {
              "name": "MLP",
              "score": 1
            },
            {
              "name": "Hopfield Network",
              "score": 1
            },
            {
              "name": "Radial Basis Function",
              "score": 1
            },
          ]
        },
        {
          "name": "Deep Learning",
          "score": 1,
          "children": [
            {
              "name": "Convolutional Neural Network",
              "score": 1
            },
            {
              "name": "Recurrent Neural Network",
              "score": 1
            },
            {
              "name": "Long Short Term Memory",
              "score": 1
            },
            {
              "name": "Auto-Encoder",
              "score": 1
            },
            {
              "name": "Generative Adversarial Network",
              "score": 1
            },
          ]
        },
        {
          "name": "Dimensionality Reduction",
          "score": 1,
          "children": [
            {
              "name": "Principal Component Analysis",
              "score": 1
            },
            {
              "name": "Linear Discriminant Analysis",
              "score": 1
            },
            {
              "name": "t-Distributed Stochastic Neighbour Embedding",
              "score": 1
            },
          ]
        },
        {
          "name": "Ensemble",
          "score": 1,
          "children": [
            {
              "name": "Bootstrap Aggregation",
              "score": 1
            },
            {
              "name": "AdaBoost",
              "score": 1
            },
            {
              "name": "Blending",
              "score": 1
            },
            {
              "name": "Stacking",
              "score": 1
            },
            {
              "name": "Gradient Boosting Machine",
              "score": 1
            },
            {
              "name": "Random Forest",
              "score": 1
            },
            {
              "name": "Voting",
              "score": 1
            },
            {
              "name": "XGBoost",
              "score": 1
            },
          ]
        },
        {
          "name": "Reinforcement Learning",
          "score": 1,
          "children": [
            {
              "name": "Q Learning",
              "score": 1
            },
            {
              "name": "Deep Q Networks",
              "score": 1
            },
          ]
        },
      ]
    },
  ]
}


// INTERESTS
export const interestsData = {
  "nodes": [

    // Wellbeing
    {
      "id": "Wellbeing",
      "height": 3,
      "size": 10,
      "color": "#b166fc"
    },
    {
      "id": "Meditation",
      "height": 2,
      "size": 8,
      "color": "#c085fa"
    },
    {
      "id": "Spirituality",
      "height": 1,
      "size": 6,
      "color": "#d2a8fb"
    },
    {
      "id": "Holistic Healing",
      "height": 1,
      "size": 6,
      "color": "#d2a8fb"
    },
    {
      "id": "Journaling",
      "height": 1,
      "size": 6,
      "color": "#d2a8fb"
    },

    // Sports
    {
      "id": "Sports",
      "height": 2,
      "size": 8,
      "color": "#66c2fc"
    },
    {
      "id": "Football",
      "height": 1,
      "size": 6,
      "color": "#8ad0fc"
    },
    {
      "id": "Athletics",
      "height": 1,
      "size": 6,
      "color": "#8ad0fc"
    },
    {
      "id": "MMA",
      "height": 1,
      "size": 6,
      "color": "#8ad0fc"
    },
    {
      "id": "Snooker",
      "height": 1,
      "size": 6,
      "color": "#8ad0fc"
    },

    // Business
    {
      "id": "Business",
      "height": 3,
      "size": 10,
      "color": "#e75c43"
    },
    {
      "id": "Startups",
      "height": 2,
      "size": 8,
      "color": "#f0735d"
    },
    {
      "id": "Public Speaking",
      "height": 2,
      "size": 8,
      "color": "#f0735d"
    },
    {
      "id": "Hackathons",
      "height": 2,
      "size": 8,
      "color": "#f0735d"
    },

    // Arts
    {
      "id": "Art",
      "height": 3,
      "size": 10,
      "color": "#fcb33b"
    },
    {
      "id": "Music",
      "height": 2,
      "size": 8,
      "color": "#fbbf5d"
    },
    {
      "id": "Technology",
      "height": 2,
      "size": 8,
      "color": "#fbbf5d"
    },
    {
      "id": "Design",
      "height": 2,
      "size": 8,
      "color": "#fbbf5d"
    },
    {
      "id": "Sketching",
      "height": 1,
      "size": 6,
      "color": "#fcca79"
    },
    {
      "id": "Gaming",
      "height": 1,
      "size": 6,
      "color": "#fcca79"
    },
    {
      "id": "Coding",
      "height": 1,
      "size": 6,
      "color": "#fcca79"
    },
    {
      "id": "AI",
      "height": 1,
      "size": 6,
      "color": "#fcca79"
    },

    // Learning
    {
      "id": "History",
      "height": 3,
      "size": 10,
      "color": "#3bcb63"
    },
    {
      "id": "Science",
      "height": 3,
      "size": 10,
      "color": "#3bcb63"
    },
    {
      "id": "Psychology",
      "height": 2,
      "size": 8,
      "color": "#54e67c"
    },
    {
      "id": "Natural History",
      "height": 2,
      "size": 8,
      "color": "#54e67c"
    },
    {
      "id": "Ancient History",
      "height": 2,
      "size": 8,
      "color": "#54e67c"
    },
    {
      "id": "Astrology",
      "height": 2,
      "size": 8,
      "color": "#54e67c"
    },
    {
      "id": "Astronomy",
      "height": 2,
      "size": 8,
      "color": "#54e67c"
    },

    // People
    {
      "id": "Teaching",
      "height": 1,
      "size": 6,
      "color": "#545263"
    },
    {
      "id": "Volunteering",
      "height": 1,
      "size": 6,
      "color": "#545263"
    },
  ],
  "links": [

    // Wellbeing
    {
      "source": "Wellbeing",
      "target": "Sports",
      "distance": 50
    },
    {
      "source": "Wellbeing",
      "target": "Meditation",
      "distance": 30
    },
    {
      "source": "Meditation",
      "target": "Spirituality",
      "distance": 20
    },
    {
      "source": "Meditation",
      "target": "Holistic Healing",
      "distance": 20
    },
    {
      "source": "Meditation",
      "target": "Journaling",
      "distance": 20
    },
    {
      "source": "Sports",
      "target": "Football",
      "distance": 20
    },
    {
      "source": "Sports",
      "target": "Athletics",
      "distance": 20
    },
    {
      "source": "Sports",
      "target": "MMA",
      "distance": 20
    },
    {
      "source": "Sports",
      "target": "Snooker",
      "distance": 20
    },

    // Business
    {
      "source": "Business",
      "target": "Startups",
      "distance": 30
    },
    {
      "source": "Business",
      "target": "Public Speaking",
      "distance": 30
    },
    {
      "source": "Business",
      "target": "Hackathons",
      "distance": 30
    },
    {
      "source": "Startups",
      "target": "Hackathons",
      "distance": 30
    },

    // Arts
    {
      "source": "Art",
      "target": "Music",
      "distance": 40
    },
    {
      "source": "Art",
      "target": "Technology",
      "distance": 50
    },
    {
      "source": "Art",
      "target": "Design",
      "distance": 30
    },
    {
      "source": "Design",
      "target": "Sketching",
      "distance": 20
    },
    {
      "source": "Technology",
      "target": "Gaming",
      "distance": 20
    },
    {
      "source": "Technology",
      "target": "Coding",
      "distance": 20
    },
    {
      "source": "Technology",
      "target": "AI",
      "distance": 20
    },

    // Learning
    {
      "source": "History",
      "target": "Science",
      "distance": 70
    },
    {
      "source": "History",
      "target": "Ancient History",
      "distance": 30
    },
    {
      "source": "History",
      "target": "Natural History",
      "distance": 30
    },
    {
      "source": "Science",
      "target": "Psychology",
      "distance": 30
    },
    {
      "source": "Science",
      "target": "Astrology",
      "distance": 30
    },
    {
      "source": "Science",
      "target": "Astronomy",
      "distance": 30
    },
    {
      "source": "Science",
      "target": "Technology",
      "distance": 80
    },
    {
      "source": "Business",
      "target": "Technology",
      "distance": 80
    },

    // People
    {
      "source": "Teaching",
      "target": "Volunteering",
      "distance": 30
    },
  ]
}

// EXPERIENCE
export interface ExperienceData {
  id: string,
  date: string,
  logo: string,
  company: string,
  badges: string[],
  description: string,
  location: string,
  roles: Array<{
    type: string,
    date: string,
    title: string,
    description: string,
    location: string,
    responsibilities: string[],
    link: string | null;
    linkText: string | null;
  }>;
}

export const experienceData: Array<ExperienceData> = [
  {
    id: "stemnet",
    date: "2014 - Present",
    logo: "stemnet.svg",
    company: "STEMNET",
    badges: ["Volunteering", "STEM", "Teaching"],
    description: "STEMNET is a UK-wide program that connects young people with STEM professionals. I have been a STEM Ambassador since 2014 taking part in school careers fairs, in-lesson teaching and school workshops focused on technology and careers in the industry.",
    location: "UK",
    roles: [
      {
        type: "role",
        date: "2014 - Present",
        title: "STEM Ambassador",
        description: "STEM Ambassadors are volunteers from a wide range of science, technology, engineering and mathematics (STEM) related jobs and disciplines. They offer their time and enthusiasm to help bring STEM subjects to reality and demonstrate the value of them in life and careers.",
        location: "UK",
        responsibilities: [
          "Delivering school classes teaching 16 year olds how technology can be used in the real world",
          "Attending school careers fairs to talk to students about careers in technology",
          "Running workshops on technology and careers in the industry"
        ],
        link: "https://www.stem.org.uk/stem-ambassadors",
        linkText: "Learn more about STEMNET"
      }
    ],
  },
  {
    id: "sap",
    date: "2014 - 2019",
    logo: "sap.svg",
    company: "SAP",
    location: "Global",
    badges: ["Software Engineering", "Data Science", "Machine Learning", "Presales"],
    description: "SAP is a German multinational software corporation that makes enterprise software to manage business operations and customer relations. I started in 2014 as a placement student and returned after graduation where I held various roles across EMEA, all focused around Data Science & Machine Learning",
    roles: [
      {
        type: "role",
        date: "2014 - 2015",
        title: "Presales Data Scientist",
        description: "Worked with the sales team to provide technical support to customers and partners. Developed proof of concepts and prototypes to demonstrate the capabilities of the SAP HANA platform.",
        location: "EMEA",
        responsibilities: [
          "Delivering customer presentations and demonstrations focused on Predictive Analytics and Big Data.",
          "Conducting client PoC's utilising proprietary & open source algorithms with customer data in Banking & Insurance.",
          "Delivering an hour's presentation on \"Big Data and Advanced Analytics\" to a customer audience at a Special Interest Group.",
          "Facilitating internal & external Design Thinking workshops.",
          "Becoming the first intern to travel abroad and share a customer best practice with the worldwide Business Intelligence and Predictive teams.",
        ],
        link: "https://community.sap.com/t5/technology-blogs-by-sap/who-could-have-predicted-that-a-placement-year-in-presales/ba-p/13140745",
        linkText: "Read my blog!"
      },
      {
        type: "role",
        date: "2016 - 2017",
        title: "Global Early Talent Academy",
        description: "Participated in the Global Early Talent Academy program in Silicon Valley, California. This was a 6 months intensive training program including industry collaboration, technical examinations and world-class soft skills & psychological training for Presales including public speaking, leadership and communication training.",
        location: "USA",
        responsibilities: [
          "Graduated ranked #1 globally based on performance, examination and feedback.",
          "Invited back for the proceeding 12 months to mentor & teach the next cohort of Presales graduates in the topics of Data Science, Machine Leaning & Big Data.",
          "As the highest performing graduate, I supported in the development of the curriculum of which is still being used today.",
          "Passed the SAP Technical ERP examination on first pass.",
          "Completed public speaking training including psychological & limbic techniques for effective communication & sales."
        ],
        link: null,
        linkText: null
      },
      {
        type: "role",
        date: "2017 - 2019",
        title: "Senior Data Scientist",
        description: "Worked as a Data Scientist within the Presales team, supporting sales cycles and delivering technical PoC's and implementations. Promoted through the ranks to Senior within 2 years whilst holding several other roles across EMEA.",
        location: "EMEA",
        responsibilities: [
          "Supported sales cycles as the Lead Presales across EMEA closing over £10m in revenue.",
          "Lead Presales responsible for delivering technical PoC's focused on Machine Learning within the UK. Delivered over 10 PoC's in 3 years.",
          "Subject matter expert for all Machine Learning, Big Data and open source technologies within SAP",
          "Created and delivered the \"AI Workshop\" for partners and customers, consisting of 3 days of training on Machine Learning and practical hands-on experience with proprietary and open source tools.",
        ],
        link: null,
        linkText: null
      },
      {
        type: "role",
        date: "2017 - 2019",
        title: "Presales Lab AI Engineer",
        description: "The Presales Lab was a team of innovative engineers focused on rapidly developing prototypes and production systems for cutting-edge customer use cases, exhibitions & showcases.",
        location: "EMEA",
        responsibilities: [
          "Developed a bike rental mobile app for TfL using location, end-point & real-time demand to recommendation where to collect and drop-off your bike and optimal pick-up times.",
          "Crafted an image dataset & built a custom trained deep learning architecture and image processing pipeline to detect 6 types of recyclable plastics. The model was integrated into the \"RecycleMate\" app and was showcased at London Design Week!",
          "Developed a recommendation system for the Princes' Trust to match mentors to vulnerable young people to reduce the number of times taken to find a good pairing.",
          "Built chat-bots across Healthcare, Utilities and Retail industries to provide a more efficient customer service experience.",
        ],
        link: null,
        linkText: null
      },
      {
        type: "role",
        date: "2018 - 2019",
        title: "Machine Learning Developer",
        description: "Joined the Global HANA Machine Learning development team based in Germany, Beijing & California to develop an open source Python package for running ML operations in-memory on the SAP HANA platform.",
        location: "Global",
        responsibilities: [
          "Collaborated with development teams in Germany, Beijing & California to launch the first open source Python package released by SAP.",
          "Solely responsible for developing the Exploratory Data Analysis module of the package, allowing for automated reports and visualisations to support ML development.",
          "Presented my work to Directors @ Germany HQ and received a 6-month extension to my secondment.",
          "Conducted code review, testing and documentation for the package.",
        ],
        link: "https://pypi.org/project/hana-ml/",
        linkText: "Check out the package!"
      },
      {
        type: "role",
        date: "2017 - 2019",
        title: "Head of Machine Learning Enablement",
        description: "As part of the EMEA Machine Learning hub I was promoted to \"Head of ML enablement\" in order to drive and deliver ML training content across EMEA to all Presales colleagues.",
        location: "EMEA",
        responsibilities: [
          "Creating Machine Learning training content for all Presales colleagues across EMEA.",
          "Delivering training workshops at quarterly and annual \"Learn to Win\" weeks.",
          "Developing creative & engaging hands-on content utilising the latest SAP & open source technologies.",
          "Supporting the running & administration of the EMEA Machine Learning hub and the development of the ML community at SAP",
        ],
        link: null,
        linkText: null
      }
    ],
  },
  {
    id: "halo",
    date: "2019 - Present",
    logo: "halo.svg",
    company: "Halo",
    location: "Global",
    badges: ["AI", "R&D", "Full Stack Dev", "Advisory", "Public Speaking"],
    description: "Halo Technology Lab is a UK-based business focused on human-centric solutions with emerging technology. We've worked with clients globally focusing on tangible impact in use cases where humanity needs it most.",
    roles: [
      {
        type: "role",
        date: "2019 - Present",
        title: "Director",
        description: "As the Director and Founder of Halo, I drive every aspect of the business to ensure the success of our projects & satisfaction of clients.",
        location: "UK",
        responsibilities: [
          "Driving sales & business development to secure new clients and projects.",
          "Working on internal R&D projects to develop new technologies and solutions.",
          "Planning and executing go-to-market strategies to commercialise our R&D projects.",
          "Collaborating with partners to jointly develop and deliver innovative solutions.",
        ],
        link: "https://halotechlab.com",
        linkText: "See more of Halo!"
      },
      {
        type: "role",
        date: "2018 - Present",
        title: "Visiting Lecturer",
        description: "Invited as a Visiting Lecturer at several universities and organisations across the world to deliver curricular and extra-curricular content on technology, AI and entrepreneurship.",
        location: "EMEA",
        responsibilities: [
          "Delivered in-person hands-on workshops and virtual lectures to Data Science & Business students at Loughborough University on Machine Learning and Data Science based topics",
          "Delivered in-person and virtual workshops to Business students at Brunel University on Data Science",
          "Invited to deliver lectures for African law firm \"TheFirma\" as part of an enablement series on AI for client Heirs Holdings",
        ],
        link: null,
        linkText: null
      },
      {
        type: "project",
        date: "2020",
        title: "Zodiac Rooms",
        description: "\"Zodiac Rooms\" is an immersive environment for mental health & spiritual reflection as well as personal discovery.",
        location: "UK",
        responsibilities: [
          "Built a dynamic immersive VR environment based on Spirituality & Zodiac signs (best Eastern and Western).",
          "The system was built using Unity and provided a \"relaxation\" and \"meditation\" zone to support healthy mental health habits whilst using technology.",
          "Developed AI algorithms to provide personalisation and customisation based on relaxation and meditation preferences.",
        ],
        link: null,
        linkText: null
      },
      {
        type: "project",
        date: "2017",
        title: "MyHudson",
        description: "\"MyHudson\" was a voice AI platform tailored for elderly residents in care homes. It provided the frontend voice interface for residents as well as backend dashboards and insights for the homes.",
        location: "UK",
        responsibilities: [
          "Developed a full stack web application for managing voice-enabled Alexa devices in care homes.",
          "Integrated with Amazon Alexa and Alexa Smart Properties platforms.",
          "Implemented at several care home groups across UK & France.",
          "\"MyHudson\" formed the basis of my later founded company, \"audico\"",
        ],
        link: null,
        linkText: null
      },
      {
        type: "project",
        date: "2019",
        title: "Wisdom AI",
        description: "Wisdom AI was a helped users reduce the stress of information overload by breaking down data on the internet into bitesize, insightful chunks.",
        location: "EMEA",
        responsibilities: [
          "Launched a Chrome extension for users to easily summarise and get insights from websites, blogs, articles & PDFs.",
          "Integrated the extension with user profiles to allow for \"snipping\" of text & images on websites as well as saving summaries",
          "Built a web app for users to create \"learning boards\" from their saved content and share with others in the community",
          "Developed language models and AI algorithms as the engine behind the technology.",
        ],
        link: null,
        linkText: null
      },
      {
        type: "project",
        date: "2019",
        title: "The Value Calculator",
        description: "The Value Calculator was a tool to help consumers make better decisions when purchasing online and to build more widespread awareness of \"value for money\".",
        location: "EMEA",
        responsibilities: [
          "Developed full stack web application and APIs to calculate the \"value\" of a product based on user preferences and reviews.",
          "Integrated with Amazon, eBay and other online retailers to provide real-time pricing and review data.",
        ],
        link: null,
        linkText: null
      },
      {
        type: "project",
        date: "2021",
        title: "Launchpad",
        description: "The Launchpad is a rapid development platform to help non-technical founders to build and launch their digital products affordably and quickly",
        location: "UK",
        responsibilities: [
          "Built an automated platform to build and deploy web applications within minutes",
          "Provided a low cost, trusted technology partner for non-technical founders to build their digital products",
          "Helping over 50 student businesses across the UK to launch their digital products across Healthcare, Mental Health, Veterinary and more",
        ],
        link: null,
        linkText: null
      },
    ]
  },
  {
    id: "audico",
    date: "2023 - Present",
    logo: "audico.svg",
    company: "audico",
    location: "Europe",
    badges: ["AI", "Healthcare", "Hospitality", "Mental Health"],
    description: "audico is a Generative AI platform specialising in integrating AI into ambient, ubiquitous devices seamlessly. We started by helping elderly residents in care homes and have now expanded to hospitality, hotels and people at home.",
    roles: [
      {
        type: "role",
        date: "2023 - Present",
        title: "Co-Founder",
        description: "I Co-Founded this company in 2023 because we saw the huge potential that ambient technology would play in the mainstream future of AI.",
        location: "Europe",
        responsibilities: [
          "I am responsible for the overall strategy and direction of the company alongside my Co-Founder and CEO",
          "Plan and execute fundraising rounds to secure investment for the company.",
          "Build and nurture corporate partnerships for joint innovation & development.",
        ],
        link: "https://audico.co.uk",
        linkText: "Learn more about audico!"
      },
      {
        type: "role",
        date: "2023 - Present",
        title: "CTO",
        description: "As CTO, I am responsible for executing our innovative and ambitious vision through technology.",
        location: "Europe",
        responsibilities: [
          "I jointly plan our product roadmap with the CEO.",
          "Responsible for leading and hiring of technical talent to build our products & services.",
          "Lead the development effort from a hands-on and strategic perspective.",
          "Lead and manage the development of our cutting-edge Generative AI services.",
          "Manage and develop our Pay-by-Voice technology stack with payment partner, Elavon.",
          "Build and lead integration work with 3rd party providers to enrich our technology stack.",
        ],
        link: "https://audico.co.uk",
        linkText: "Learn more about audico!"
      }
    ]
  },
]

// STORIES
export interface Story {
  id: string;
  title: string;
  company: string;
  filter: string[];
  body: string;
  longBody: string[];
  quote: string | null;
  quoteSource: string | null;
  summary: string;
  link: string | null;
  linkText: string | null;
  figure: string | null;
  figureText: string | null;
  figureChange: string | null;
  showOnHome: boolean;
}

export const featuredStory: Story = {
  id: "audico-pay-by-voice",
  title: "Pay-by-Voice using AI",
  company: "audico",
  filter: ["ai", "payments"],
  body: "Through our partnership with payment provider, Elavon (part of US bank), I am responsible for all AI driven payment developments for audico. So far, we've built a one-of-a-kind payment system to faciliate payments using just your voice. This has had signicant coverage and we are currently onboarding Elavon to sell this product in their sales kitbag.",
  longBody: [],
  quote: "Virtual assistant technology is the future for hospitality, and we're thrilled to be powering the payments behind Audico",
  quoteSource: "Hemlata Narasimhan, President of Elavon Europe",
  summary: "Developed industry leading Pay-by-Voice system powered by AI",
  link: "https://www.elavon.co.uk/insights/news/pay-by-voice.html",
  linkText: "See the Press Release!",
  figure: null,
  figureText: null,
  figureChange: null,
  showOnHome: true,
}


// Tylcham game discovery
export const stories: Story[] = [
  // audico
  {
    id: "audico-rafa",
    title: "AI for elderly veterans",
    company: "audico",
    filter: ["ai", "mental-health", "healthcare"],
    body: "Designed and built an AI platform tailored for elderly residents and care homes, which now services all of the Royal Air Forces Association (RAFA) tenants across the UK.",
    longBody: [],
    quote: "What we are doing is not so much assisting people, we are assisting their independance and audico dovetails into that really well",
    quoteSource: "Nick Bunting, CEO of RAFA",
    summary: "AI platform for elderly veterans",
    link: "https://www.youtube.com/watch?v=uy_oQDZK0Sc",
    linkText: "See more!",
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "audico-sales",
    title: "Revenue generation with AI",
    company: "audico",
    filter: ["ai", "payments"],
    body: "Implemented our AI driven sales system at Ascot Racecourse, which has increased sales by 200% with an average order value by £50.",
    longBody: [],
    quote: "We've seen revenues on race days for participating boxes more than double since we started using audico. It has been a game-changer",
    quoteSource: "Paul Paisis-Oakwell, Head of F&B @ Ascot Racecourse",
    summary: "Revenue generation with AI",
    link: "https://www.youtube.com/watch?v=eNRvAWBnfTE",
    linkText: "See more!",
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "audico-at-home",
    title: "Tackling loneliness & isolation",
    company: "audico",
    filter: ["ai", "mental-health", "healthcare"],
    body: "3.3 million people over 65 years old are living alone in England and Wales. Charities such as RAFA handle over 10,000's calls each month from this audience simply due to loneliness. With this in mind, I designed and built an AI powered companion service for lonely & isolated elderly people at home. We're currently running a pilot with RAFA and look to scale to their network of 80,000 alumni, before scaling across the UK.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "audico at home",
    link: null,
    linkText: null,
    figure: "3,300,000",
    figureText: "people over 65 suffer from loneliness",
    figureChange: "increase",
    showOnHome: true,
  },

  /////// Halo
  {
    id: "halo-keyword-extraction",
    title: "Ubiquitous keyword extraction",
    company: "Halo",
    filter: ["ai", "data-science"],
    body: "Developed a ubiquitous keyword extraction system to operate on any form of textual data. This LLM free approach is 95% faster than the average LLM, requires no training and uses custom taxonomies for hyper personalisation. It also has in-built hierarchical understanding to show semantic meaning and relationships. I developed this algorithm and it has since been utilised with various clients globally.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Ubiquitous keyword extraction",
    link: null,
    linkText: null,
    figure: "95%",
    figureText: "faster than the average LLM",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-knowledge-graph",
    title: "Semantic knowledge graph",
    company: "Halo",
    filter: ["ai", "data-science"],
    body: "Developed graph-based algorithms to construct massive multi-dimensional knowledge graphs from unstructured text data. This set of algorithms and graph database was used to power intelligent search and recommendation systems for various clients.",
    longBody: [],
    quote: "Arun is an excellent Data Scientist with a great work ethic.",
    quoteSource: "Founder of Storyroom",
    summary: "Semantic knowledge graph",
    link: null,
    linkText: null,
    figure: "1,000,000s",
    figureText: "keywords organised hierarchically and relationally",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-salient-moment-analysis",
    title: "Salient moment identification & generation",
    company: "Halo",
    filter: ["ai", "data-science"],
    body: "Built a framework and pipeline utilising LLMs & Computer Vision to identify salient moments in video content. The algorithm then automatically generated suggestions based on the salient moments for content using the pre-defined user objectives.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Semantic knowledge graph",
    link: null,
    linkText: null,
    figure: "97%",
    figureText: "reduction in manual labour for content generation",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-test-likeness",
    title: "Medical exam content corpus audit",
    company: "Halo",
    filter: ["ai", "data-science", "healthcare"],
    body: "Built a series of algorithms & framework to predict the ambiguity and quality of medical exam content based on the content corpus. This was used to prioritise content for review and improvement as well as to identify gaps in the content.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Content corpus audit",
    link: null,
    linkText: null,
    figure: "> 50,000",
    figureText: "exam questions analysed",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-conversational-search",
    title: "Medical exam conversational search",
    company: "Halo",
    filter: ["ai", "software-engineering", "healthcare"],
    body: "Developed a conversational agent using LLMs & RAG architecture to provide a human-like conversational interface to support students whilst learning on a medical exam platform. The agent was embedded as a series of APIs for future integration and presented within a web application.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Conversational search",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "halo-exam-evaluation",
    title: "Medical exam AI evaluation",
    company: "Halo",
    filter: ["ai", "software-engineering", "healthcare"],
    body: "Built an AI system to qualitatively and quantitatively evaluate medical students exam answers and provide a full written report as feedback. The algorithm was embedded within a web application to allow for inline-editing and submission to the student.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "AI exam evaluation",
    link: null,
    linkText: null,
    figure: "99%",
    figureText: "reduction in cost for exam evaluation",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "halo-sustainable-product-recommendation",
    title: "Sustainable product recommendation",
    company: "Halo",
    filter: ["ai", "sustainability"],
    body: "Built an NLP & Computer Vision engine to recommend sustainable alternatives to online shoppers when they visit retail product pages. The personalised recommendations were based on the users preferences and previous purchases. The engine was integrated into the companies existing platform and a Chrome extension.",
    longBody: [],
    quote: "We can not recommend Arun enough, he is one of our greatest assets and we'll continue to work with him.",
    quoteSource: "Harry G, Founder of Ango",
    summary: "Sustainable product recommendation",
    link: null,
    linkText: null,
    figure: "> 1,000,000",
    figureText: "products that the algorithm could provide recommendations for",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "halo-spinal-plate-detection",
    title: "Surgical implant identification",
    company: "Halo",
    filter: ["ai", "data-science", "healthcare"],
    body: "Designed and developed a Computer Vision algorithm to identify of surgical plates in X-ray images. I used a novel \"Bag of Visual Words\" approach to achieve over 90% accuracy with extremely low quantities of data.",
    longBody: [],
    quote: "It feels good to know that 1000's of surgeons and industry reps rely completely on it to adequately treat their patients.",
    quoteSource: "Matthew Twite, Founder of Arnold",
    summary: "Sustainable product recommendation",
    link: null,
    linkText: null,
    figure: "> 10,000",
    figureText: "surgical plates identified since it's launch",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "halo-igloo",
    title: "Online safe space for journaling",
    company: "Halo",
    filter: ["ai", "software-engineering", "mental-health", "healthcare"],
    body: "Designed and built the full stack of technology for the mental health journaling app, Igloo. Igloo is an online safe space for journaling that visually displays your mental health state, goals and history. Through AI insights & analytics, Igloo helps you to take accountability, identify trigger points and take action for your mental health.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Online safe space for journaling",
    link: "https://entertheigloo.xyz",
    linkText: "Try it for Free!",
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "halo-zodiac-rooms",
    title: "Therapeutic immersive environment",
    company: "Halo",
    filter: ["ai", "software-engineering", "mental-health", "vr", "healthcare"],
    body: "Designed an AI & VR engine to create dynamic immersive environments for mental health therapy. Using Deep Q Learning and sensors, the engine tracks the users mental health state throug stress and dynamically updates the VR environment based on how stressed they are. The engine learns over time your \"stress profile\" and can provide personalised therapeutic exercises in just 3 minutes.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Dynamic immersive environment",
    link: null,
    linkText: null,
    figure: "3 minutes",
    figureText: "or less to regulate stress levels through personalised exercises",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "halo-web-crawlers",
    title: "Big data web scraping",
    company: "Halo",
    filter: ["software-engineering", "healthcare"],
    body: "Designed and built web crawlers to collect over 12,000,000 observations across Sport, Retail, Healthcare & Media. I'm able to build robust crawlers by utilising the latest techniques such as proxy rotation, headless browsing and CAPTCHA solving. Combining this with data processing pipelines and headless browsers result in massive quantities of high quality data.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Big data web scraping",
    link: null,
    linkText: null,
    figure: "> 12,000,000",
    figureText: "observations collected from across the web",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-forecasts",
    title: "Profitability forecasts",
    company: "Halo",
    filter: ["data-science"],
    body: "Designed and built a forecasting system to predict the profitability of a company based on their historical data. The system was built using a combination of Machine Learning and traditional statistical methods to provide a robust and accurate forecast which was used to raise a further £50,000,000 of investment.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Profitability forecasts",
    link: null,
    linkText: null,
    figure: "£50,000,000",
    figureText: "investment raised based on decisions from the forecast",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-day-2-day",
    title: "AI interaction app for mental reflection",
    company: "Halo",
    filter: ["ai", "software-engineering", "mental-health", "healthcare"],
    body: "Designed and built a full stack web application to provide daily reflections of the users interactions on the internet. The app uses AI to provide insights into the users mental health state and provides suggestions for improvement.",
    longBody: [],
    quote: "Working with Arun has been an absolute pleasure. From day one, his expertise in AI and machine learning was evident. Arun brought not only technical knowledge but also a creative approach to solving complex problems. Whether we were brainstorming new ideas or troubleshooting issues, Arun was reliable, resourceful, and incredibly easy to work with. If you're looking for a skilled AI expert who also excels at teamwork, Arun is the perfect fit. I highly recommend him for any AI-related projects and look forward to working with him again in the future!",
    quoteSource: "Daniel N, Founder of Day 2 Day",
    summary: "AI interaction app for mental reflection",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "halo-web-summarisation",
    title: "Breaking down the Web",
    company: "Halo",
    filter: ["ai", "software-engineering"],
    body: "Before the rise of LLM's, I developed a summarisation system using Computer Vision, NLG & NLP that could summarise any web page, article, blog or PDF from across the internet and provide concise key-points. I developed an accompanying web app and Chrome extension for users to save these snippets and create learning boards for later reference and sharing.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Breaking down the Web",
    link: null,
    linkText: null,
    figure: "Product Hunt",
    figureText: "featured our launch and we had over 1000 users in the first week",
    figureChange: "increase",
    showOnHome: true,
  },

  // SAP
  {
    id: "sap-terror-detection",
    title: "Threat detection algorithm",
    company: "SAP",
    filter: ["data-science"],
    body: "Developed a geo-location based graph algorithm capable of identifying risky people travelling across the UK in case of a terrorist threat. Looking at transaction history, anomaly detection & OSINT we were able to construct a dataset that was highly predictive.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Threat detection algorithm",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "halo-ensemble-forecasting",
    title: "True demand forecasting",
    company: "Halo",
    filter: ["data-science"],
    body: "Developed an ensemble forecasting system to predict the demand for products in the retail sector. The algorithm could predict top-down or bottom-up and was able to forecast retail demand on a daily & monthly level to 97% accuracy across 1000 product categories and 100,000 product lines.",
    longBody: [],
    quote: "Arun is world class. Both in his craft, in his communication and in his method of delivery. One of the best data scientists I've worked with.",
    quoteSource: "Oscar, Founder of L7",
    summary: "Segmented recommendation engine",
    link: null,
    linkText: null,
    figure: "97%",
    figureText: "demand accuracy across 1000 categories and 100,000 lines of products",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "halo-recommendation-engine",
    title: "Segmented recommendation engine",
    company: "Halo",
    filter: ["data-science"],
    body: "Developed segmented recommendation algorithms for an e-commerce recommendation engine using classical machine learning and statistical modelling methods. By segmenting the recommendations, in just 6 months the algorithms that I built were able to produce over 1000 different types of recommendations for users, e.g. which bundles of products should I sell? What price should I sell them at? Which target group should I promote these products to?",
    longBody: [],
    quote: "He showed his entrepreneurial spirit by designing network graph algorithms for the recommendations of upsell opportunities. It was a fantastic experience working with Arun and we're keen to work together again in the future.",
    quoteSource: "Arun S, founder of Exii",
    summary: "Segmented recommendation engine",
    link: null,
    linkText: null,
    figure: "> 1000",
    figureText: "different types of recommendations produced for e-commerce platform",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "sap-bakery",
    title: "Greggs data science",
    company: "SAP",
    filter: ["data-science"],
    body: "Worked with UK food retailer Greggs to build algorithms for product recommendation, store locations and sales forecasting. We used Social Network Analysis to enrich product recommendations that gave real-time product suggestions when a customer walks in a store. We used geo-spatial and open source data such as food standard ratings, local amenities etc... to predict the best locations to open new stores based on predicted revenue.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Greggs data science",
    link: null,
    linkText: null,
    figure: "> 100,000,000",
    figureText: "rows of data analysed to provide profitable recommendations",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "sap-tfl",
    title: "TfL bike rental app",
    company: "SAP",
    filter: ["data-science", "sustainability", "software-engineering"],
    body: "Built a bike rental app for Transport for London using their open data API. The app used your current location to give personalised recommendations on where to rent a bike at the desired time, based on historical demand. The app also gave real-time bike availability, journey planning and cost estimations predictions.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Greggs data science",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "sap-princes-trust",
    title: "Mentor matching",
    company: "SAP",
    filter: ["data-science", "mental-health", "software-engineering"],
    body: "Built a mentor matching app for the Princes' Trust to match mentors with mentees based on their skills, personality, location and availability. The Princes' Trust aids young people in need of support, so finding the right mentor as quickly as possible is a huge challenge. I won the SAP Innovation Hackathon with this project and worked with the Princes' Trust to progress the idea.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Mentor matching",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    figureChange: null,
    showOnHome: true,
  },
  {
    id: "sap-recyclemate",
    title: "Intelligent recycling",
    company: "SAP",
    filter: ["ai", "sustainability", "software-engineering"],
    body: "Built a recycling app that was featured at London Design Week that used Computer Vision to identify the type of waste and provide recycling instructions. The app was able to identify all 7 types of recyclable plastic and provide a gamified experience to encourage people to recycle in bulk.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Mentor matching",
    link: null,
    linkText: null,
    figure: "London Design Week",
    figureText: "featured the app as an innovation in sustainability!",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "sap-utilities-forecast",
    title: "Utility usage prediction",
    company: "SAP",
    filter: ["data-science"],
    body: "Refactored 25 year olf FORTRAN code and developed ensemble based algorithms to accurately predict daily usage of gas and electricity in homes across the UK. Was able to achieve a 97% accuracy and save the business 45% of their forecasting costs.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Mentor matching",
    link: null,
    linkText: null,
    figure: "45%",
    figureText: "reduction in forecasting costs",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "sap-hospital-demand",
    title: "Inpatient, daycase and revenue predictions",
    company: "SAP",
    filter: ["healthcare", "data-science"],
    body: "Developed a regression algorithm to replace a 20 year old manual forecasting system for a leading UK healthcare provider. It predicted demand for Inpatients, Daycases and Revenue with 95% average accuracy and reduced the time to forecast by 97.5%.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Healthcare predictions",
    link: null,
    linkText: null,
    figure: "97.5%",
    figureText: "reduction in analysis time",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "sap-product-sales",
    title: "Product sales demand",
    company: "SAP",
    filter: ["data-science"],
    body: "Developed algorithms for a leading Dutch retailer to accurately predict the sales volume of over 1500 products on a daily basis. The algorithms were implemented and used to optimise the supply chain and reduce waste.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Product sales demand",
    link: null,
    linkText: null,
    figure: "1500",
    figureText: "product line predictions within minutes every day",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "sap-viking",
    title: "Shipping route optimisation",
    company: "SAP",
    filter: ["data-science", "sustainability"],
    body: "Worked with leading shipping provider in Denmark to develop algorithms capable of optimising shipping routes based on weather, traffic and demand. The algorithms were able to reduce fuel consumption by 20% and increase delivery times by 15%.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Shipping route optimisation",
    link: null,
    linkText: null,
    figure: "20%",
    figureText: "reduction in fuel consumption",
    figureChange: "decrease",
    showOnHome: true,
  },
  {
    id: "sap-fault-detection",
    title: "Manufacturing robot fault detection",
    company: "SAP",
    filter: ["ai"],
    body: "Using audio data from a global leading manufacturer of cars in Japan, I developed a deep learning CNN algorithm that used frequency spectrograms to identify if a robot was about to fail. The algorithm was able to predict 90% of failures before they happened and was nominated for the SAP Innovation Award.",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "Manufacturing robot fault detection",
    link: null,
    linkText: null,
    figure: "Innovation Award",
    figureText: "nomination for the novel approach to fault detection, i.e. converting sound to images",
    figureChange: "increase",
    showOnHome: true,
  },
  {
    id: "sap-open-source",
    title: "HANA ML Python Package",
    company: "SAP",
    filter: ["ai", "data-science", "software-engineering"],
    body: "Spent 12 months working with the SAP HQ development team in Frankfurt, California and Beijing to develop and launch the Open Source HANA ML Python package. The package was designed to allow data scientists to easily connect to the HANA database and run ML algorithms in memory. I contributed by developed more efficient EDA algorithms and visualisation capabilities, reducing the load time by > 90%",
    longBody: [],
    quote: null,
    quoteSource: null,
    summary: "HANA ML Python Package",
    link: "https://pypi.org/project/hana-ml/",
    linkText: "See the package!",
    figure: "> 90%",
    figureText: "reduction in load and waiting times",
    figureChange: "decrease",
    showOnHome: true,
  },
]

// RECOGNITION
export interface RecognitionData {
  id: string;
  route: string[];
  backgroundIcon: any;
  backgroundIconColour: string;
  title: string;
  shortBody: string;
  longBody: string;
  entity: string | null;
  entityLogo: string | null;
  link: string | null;
  linkText: string | null;
  figure: string | null;
  figureText: string | null;
  tags: [string, string][];
  showOnHome: boolean;
}

export const recognitionTree: any = {
  "id": "recognition",
  "openDetail": false,
  "name": "Recognition",
  "color": "hsl(247, 17%, 35%)",
  "children": [
    {
      "id": "career",
      "openDetail": false,
      "name": "Career",
      "color": "hsl(203, 60%, 69%)",
      "children": [
        {
          "id": "audico",
          "openDetail": false,
          "name": "audico",
          "color": "primary",
          "children": [
            {
              "id": "audico-award",
              "openDetail": false,
              "name": "Award",
              "color": "warning",
              "children": [
                {
                  "id": "t4g",
                  "openDetail": true,
                  "name": "Tech4Good Award",
                  "color": "warning",
                },
                {
                  "id": "elavon-cpa",
                  "openDetail": true,
                  "name": "Card & Payments Award",
                  "color": "warning",
                }
              ]
            },
            {
              "id": "audico-interview",
              "openDetail": false,
              "name": "Interview",
              "color": "dark",
              "children": [
                {
                  "id": "sap-ai",
                  "openDetail": true,
                  "name": "SAP AI Interview",
                  "color": "dark",
                },
              ]
            },
            {
              "id": "audico-feature",
              "openDetail": false,
              "name": "Feature",
              "color": "success",
              "children": [
                {
                  "id": "alexa-ascot",
                  "openDetail": true,
                  "name": "Alexa Innovators Series",
                  "color": "dark",
                },
                {
                  "id": "alexa-canford",
                  "openDetail": true,
                  "name": "Canford Care Feature Film",
                  "color": "dark",
                },
                {
                  "id": "alexa-newsletter",
                  "openDetail": true,
                  "name": "Innovation Newsletter",
                  "color": "dark",
                }
              ]
            }
          ]
        },
        {
          "id": "sap",
          "openDetail": false,
          "name": "SAP",
          "color": "success",
          "children": [
            {
              "id": "sap-award",
              "openDetail": false,
              "name": "Award",
              "color": "warning",
              "children": [
                {
                  "id": "sap-gps",
                  "openDetail": true,
                  "name": "Gifted Public Speaker",
                  "color": "warning",
                },
                {
                  "id": "sap-1",
                  "openDetail": true,
                  "name": "Ranked #1 in Global Talent Academy",
                  "color": "warning",
                },
                {
                  "id": "sap-btp",
                  "openDetail": true,
                  "name": "Best Team Player",
                  "color": "warning"
                },

              ]
            },
            {
              "id": "sap-certification",
              "openDetail": false,
              "name": "Certification",
              "color": "warning",
              "children": [
                {
                  "id": "sap-l4",
                  "openDetail": true,
                  "name": "Predictive Analytics Level 4 Certification",
                  "color": "warning",
                },
              ]
            },
            {
              "id": "sap-content",
              "openDetail": false,
              "name": "Content",
              "color": "warning",
              "children": [
                {
                  "id": "sap-blogs",
                  "openDetail": true,
                  "name": "SAP Blogs",
                  "color": "warning",
                },
              ]
            },
          ]
        },
        {
          "id": "halo",
          "openDetail": false,
          "name": "Halo",
          "color": "danger",
          "children": [
            {
              "id": "halo-award",
              "openDetail": false,
              "name": "Award",
              "color": "warning",
              "children": [
                {
                  "id": "upwork-ev",
                  "openDetail": true,
                  "name": "Expert-Vetted Badge",
                  "color": "success",
                }
              ]
            },
            {
              "id": "halo-feature",
              "openDetail": false,
              "name": "Feature",
              "color": "warning",
              "children": [
                {
                  "id": "upwork-showcase",
                  "openDetail": true,
                  "name": "Upwork Showcase",
                  "color": "success",
                },
                {
                  "id": "halo-upwork-success",
                  "openDetail": true,
                  "name": "Upwork Success Story",
                  "color": "success",
                },
                {
                  "id": "halo-forage-se-sd",
                  "openDetail": true,
                  "name": "Software Developer vs Software Engineer",
                  "color": "success",
                },
                {
                  "id": "halo-forage-ai",
                  "openDetail": true,
                  "name": "How to get into AI",
                  "color": "success",
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "academia",
      "openDetail": false,
      "name": "Academia",
      "color": "hsl(270, 47%, 75%)",
      "children": [
        {
          "id": "academia-lecturing",
          "openDetail": false,
          "name": "Lecturing",
          "color": "info",
          "children": [
            {
              "id": "guest-lecturer",
              "openDetail": true,
              "name": "Visiting Lecturer",
              "color": "success",
            }
          ]
        },
        {
          "id": "academia-research",
          "openDetail": false,
          "name": "Research",
          "color": "info",
          "children": [
            {
              "id": "jrme-sna",
              "openDetail": true,
              "name": "Research Publication",
              "color": "success",
            },
            {
              "id": "audico-research-uos",
              "openDetail": true,
              "name": "Voice AI Research",
              "color": "success",
            }
          ]
        },
        {
          "id": "academia-ent",
          "openDetail": false,
          "name": "Entrepreneurship",
          "color": "info",
          "children": [
            {
              "id": "academia-ent-award",
              "openDetail": true,
              "name": "Award",
              "color": "success",
              "children": [
                {
                  "id": "uos-eoy",
                  "openDetail": true,
                  "name": "Entrepreneur of the Year",
                  "color": "warning",
                },
                {
                  "id": "uos-santander-foundership",
                  "openDetail": true,
                  "name": "Santander Foundership",
                  "color": "warning",
                },
                {
                  "id": "audico-santander-x",
                  "openDetail": true,
                  "name": "Santander X",
                  "color": "warning",
                },
                {
                  "id": "uos-5g",
                  "openDetail": true,
                  "name": "5G Centre Hackathon",
                  "color": "warning",
                },
                {
                  "id": "uos-3ds",
                  "openDetail": true,
                  "name": "3DS Hackathon Winner",
                  "color": "warning",
                }

              ]
            },
            {
              "id": "academia-ent-content",
              "openDetail": true,
              "name": "Content",
              "color": "success",
              "children": [
                {
                  "id": "uos-podcast",
                  "openDetail": true,
                  "name": "Start Up Surrey Podcast",
                  "color": "warning",
                },
              ]
            }
          ]
        },
      ]
    },
    {
      "id": "personal",
      "openDetail": false,
      "name": "Personal",
      "color": "hsl(9, 61%, 65%)",
      "children": [
        {
          "id": "personal-content",
          "openDetail": false,
          "name": "Content",
          "color": "info",
          "children": [
            {
              "id": "personal-data-science",
              "openDetail": true,
              "name": "Data Science",
              "color": "success",
              "children": [
                {
                  "id": "kaggle-content",
                  "openDetail": true,
                  "name": "Kaggle Contributor & Medals",
                  "color": "warning",
                }
              ]
            }
          ]
        },
        {
          "id": "personal-sport",
          "openDetail": false,
          "name": "Sport",
          "color": "info",
          "children": [
            {
              "id": "football-academy",
              "openDetail": true,
              "name": "Football Academy",
              "color": "success",
            },
            {
              "id": "youth-athletics",
              "openDetail": true,
              "name": "Athletic Achievements",
              "color": "success",
            }
          ]
        }
      ]
    }
  ]
}

export const recognition: any = [
  {
    id: "halo-upwork-success",
    route: ["Career", "Halo", "Feature"],
    backgroundIcon: TrophyIcon,
    backgroundIconColour: "warning",
    title: "Upwork Success Story",
    shortBody: "Chosen to feature as a \"Top AI Specialist\" and tell my success story",
    longBody: "As part of Halo's mission to build meaningful & purposeful technology solutions we have leveraged platforms such as Upwork to grow our client base and reputation as a brand. Over 5 years, we have supported over 50 businesses and received a perfect 5 star rating across all of our reviews. From the success on this platform, I was chosen to feature as a \"Top AI Specialist\" and tell my success story.",
    entity: "Halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: "https://www.upwork.com/en-gb/success-stories/tips-from-ai-freelancer",
    linkText: "Read the story here!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "info"],
      ["Business", "danger"],
      ["Leadership", "warning"],
    ],
    showOnHome: true,
  },
  {
    id: "halo-forage-ai",
    route: ["Career", "Halo", "Feature"],
    backgroundIcon: TrophyIcon,
    backgroundIconColour: "warning",
    title: "How to get into AI",
    shortBody: "Featured by global e-learning platform \"Forage\" to write an article on \"How to get into AI\".",
    longBody: "Collaborated with global e-learning platform \"Forage\" to create course content across Data Science, AI & Software Engineering. As part of this collaboration, I was featured to contribute my expertise toward the article \"How to get into AI\".",
    entity: "Halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: "https://www.theforage.com/blog/careers/how-to-get-into-artificial-intelligence",
    linkText: "See the article!",
    figure: null,
    figureText: null,
    tags: [
      ["Education", "dark"],
      ["AI", "info"],
      ["Content", "danger"],
    ],
    showOnHome: true,
  },
  {
    id: "halo-forage-se-sd",
    route: ["Career", "Halo", "Feature"],
    backgroundIcon: TrophyIcon,
    backgroundIconColour: "warning",
    title: "Software Developer vs Software Engineer",
    shortBody: "Featured by global e-learning platform \"Forage\" to contribute my expertise for the article on \"Software Developer vs Software Engineer: What's the difference?\".",
    longBody: "Collaborated with global e-learning platform \"Forage\" to create course content across Data Science, AI & Software Engineering. As part of this collaboration, I was featured to contribute my expertise toward the article \"Software Developer vs Software Engineer: What's the difference?\".",
    entity: "halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: "https://www.theforage.com/blog/careers/software-developer-vs-software-engineer",
    linkText: "Read the article!",
    figure: null,
    figureText: null,
    tags: [
      ["Education", "dark"],
      ["AI", "info"],
      ["Software", "primary"],
      ["Content", "danger"],
    ],
    showOnHome: true,
  },
  {
    id: "uos-podcast",
    route: ["Academia", "Entrepreneurship", "Content"],
    backgroundIcon: TrophyIcon,
    backgroundIconColour: "warning",
    title: "Start Up Surrey Podcast",
    shortBody: "Invited to speak on the Start Up Surrey podcast about my journey in entrepreneurship, specifically social entrepreneurship and my work in AI and data science.",
    longBody: "As part of my continued collaboration with the University of Surrey and support for businesses within the Student Enterprise hub, I was invited to speak on the Start Up Surrey podcast about my journey in entrepreneurship, specifically social entrepreneurship and my work in AI and Data Science.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://open.spotify.com/episode/4xu9WcyyRwRPgS5pDGWYGe",
    linkText: "Listen to the episode here!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "primary"],
      ["Data Science", "success"],
      ["Society", "warning"],
    ],
    showOnHome: true,
  },
  {
    id: "t4g",
    route: ["Career", "audico", "Award"],
    backgroundIcon: TrophyIcon,
    backgroundIconColour: "warning",
    title: "Tech4Good Award",
    shortBody: "Finalist nominee for the Tech4Good award for our work in AI & mental health at audico.",
    longBody: "One reason why we started audico was to create simple technology solutions for people that need it most, which is why our initial focus was elderly residents in care homes. Through our work with these organisations, as well as our vision to tackle loneliness, isolation & connectivity for over 1.5 million people across the UK we were nominated as a finalist for the \"Tech4Good\" award within the AI & mental health category.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7077962172416286721",
    linkText: "Find out more here!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "warning"],
      ["Startup", "primary"],
      ["Mental health", "info"],
    ],
    showOnHome: true,
  },
  {
    id: "elavon-cpa",
    route: ["Career", "audico", "Award"],
    backgroundIcon: TrophyIcon,
    backgroundIconColour: "warning",
    title: "Card & Payments Award",
    shortBody: "Finalist for the Card & Payments award 2024 for our innovative \"Pay-by-Voice\" technology in partnership with Elavon & US Bank.",
    longBody: "Through our partnership with global payment provider, Elavon (US Bank), we were nominated as a finalist for the Card & Payments award 2024 for our innovative \"Pay-by-Voice\" technology. This technology represented a ground-breaking, world-first payment system running on Alexa and Android devices to allow people to complete payment within seconds just using their voice.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://www.linkedin.com/posts/elavon-europe_it-was-an-honour-to-be-shortlisted-against-activity-7160274781303447552-PC0P",
    linkText: "Show me more!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "info"],
      ["Payments", "danger"],
      ["Innovation", "warning"],
    ],
    showOnHome: true,
  },
  {
    id: "sap-ai",
    route: ["Career", "audico", "Interview"],
    backgroundIcon: VideoCameraIcon,
    backgroundIconColour: "dark",
    title: "SAP AI Interview",
    shortBody: "With the ubiquitous shift toward AI, I was interviewed by SAP to discuss the future of AI, how it will impact the enterprise and the innovative use cases of AI that I have been working on.",
    longBody: "With the shift toward AI becoming so ubiquitous, I was interviewed by SAP to discuss the future of AI, how it will impact the enterprise and the innovative use cases of AI that I have been working on. I was invited by Machine Learning Centre of Excellence leader, Mikal Netteberg, to record an interview to be broadcast internally across SAP globally as part of the quarterly \"All Hands call\" as well as externally.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://sapvideoa35699dc5.hana.ondemand.com/?entry_id=1_fode8rp3",
    linkText: "Watch the interview!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "warning"],
      ["Collaboration", "dark"],
      ["Innovation", "info"],
    ],
    showOnHome: true,
  },
  {
    id: "upwork-ev",
    route: ["Career", "Halo", "Award"],
    backgroundIcon: CheckBadgeIcon,
    backgroundIconColour: "success",
    title: "Expert-Vetted Badge",
    shortBody: "Hand picked by Upwork as \"Expert-Vetted\". This highlights talent that has passed a thorough pre-screening process by experts in their field. This badges recognises elite talent that have been evaluated by Upwork's Talent Managers for their expertise and excellent soft skills. They represent the Top 1% of talent on Upwork.",
    longBody: "As part of Halo's mission to build meaningful & purposeful technology solutions we have leveraged platforms such as Upwork to grow our client base and reputation as a brand. Over 5 years, we have supported over 50 businesses and received a perfect 5 star rating across all of our reviews. From this success, I was hand picked by Upwork as \"Expert-Vetted\". This highlights talent that has passed a thorough pre-screening process by experts in their field. This badges recognises elite talent that has been evaluated by Upwork's Talent Managers for their expertise and excellent soft skills. They represent the Top 1% of talent on Upwork.",
    entity: "Halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: "https://www.upwork.com/freelancers/~01de3733a6ffb1f9a5",
    linkText: "Find my profile here!",
    figure: null,
    figureText: null,
    tags: [
      ["Talent", "success"],
      ["Business", "primary"],
      ["Leadership", "warning"],
    ],
    showOnHome: true,
  },
  {
    id: "alexa-ascot",
    route: ["Career", "audico", "Interview"],
    backgroundIcon: LightBulbIcon,
    backgroundIconColour: "info",
    title: "Alexa Innovators Series",
    shortBody: "Featured across the Amazon Alexa platform as an innovator in the voice AI space. This included showcasing my work through audico in the Hospitality",
    longBody: "Since we founded audico we work closely with market leading hardware and software providers such as Amazon, for their voice enabled Alexa devices and platform. Some of our most innovative use cases of the audico technology platform has been deployed working on the Alexa foundation, of which we received significant press, coverage, exposure and funding from various Amazon entities. Amazon HQ sent a feature film crew from North America to capture the innovation and success of our system working at Ascot Racecourse. This feature received global coverage across all of Amazon's social channels.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://www.linkedin.com/posts/amazon-alexa-developers_go-behind-the-scenes-in-this-deep-dive-episode-activity-7123416775261581313-dP3u",
    linkText: "Watch the video!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "warning"],
      ["Innovation", "dark"],
      ["Collaboration", "primary"],
    ],
    showOnHome: true,
  },
  {
    id: "alexa-canford",
    route: ["Career", "audico", "Feature"],
    backgroundIcon: HeartIcon,
    backgroundIconColour: "danger",
    title: "Canford Care Feature Film",
    shortBody: "The Amazon HQ sent a film crew to produce a feature film on trailblazing work that audico undertook at Canford Care Home on the Amazon Alexa Voice AI platform. This video has now been viewed over 1.4 million times and we were told that even Jeff Bezos saw the video!",
    longBody: "We started our work at audico in the elderly care industry when ambient AI was still in it's mainstream infancy. Many people were cautious and even skeptical of the technology in such a sensitive scenario, however we saw a huge opportunity with accessible technology to improve the quality of life of residents and staff. The Amazon HQ in North America sent a film crew to produce a feature film on the trailblazing work that audico undertook at Canford Care Home on the Amazon Alexa Voice AI platform. This video has now been viewed over 1.4 million times and we were told that even Jeff Bezos saw the video!",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://www.youtube.com/watch?v=U5vwhVDRT_8",
    linkText: "Watch the film!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "warning"],
      ["Success story", "success"],
      ["Healthcare", "danger"],
    ],
    showOnHome: true,
  },
  {
    id: "upwork-showcase",
    route: ["Career", "Halo", "Feature"],
    backgroundIcon: VideoCameraIcon,
    backgroundIconColour: "primary",
    title: "Upwork Showcase",
    shortBody: "Due to my client satisfaction, project success and overall performance on AI projects, Upwork created a feature showcase about myself and how I add value to clients in the AI domain. Even the CEO shared this video on their LinkedIn!",
    longBody: "As part of Halo's mission to build meaningful & purposeful technology solutions we have leveraged platforms such as Upwork to grow our client base and reputation as a brand. Over 5 years, we have supported over 50 businesses and received a perfect 5 star rating across all of our reviews. Due to client satisfaction, project success and overall performance on AI projects, Upwork created a feature showcase about myself and how I add value to clients in the AI domain. Even the CEO shared this video on their LinkedIn!",
    entity: "Halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: "https://www.youtube.com/watch?v=1-H1rD00hG8",
    linkText: "Watch my showcase!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "warning"],
      ["Business", "primary"],
      ["Success", "success"],
    ],
    showOnHome: true,
  },
  {
    id: "alexa-newsletter",
    route: ["Career", "audico", "Feature"],
    backgroundIcon: BoltIcon,
    backgroundIconColour: "dark",
    title: "Innovation Newsletter",
    shortBody: "Featured in the Amazon Alexa Innovators newsletter for my consistent and forward thinking work with voice AI.",
    longBody: "Since we founded audico we work closely with market leading hardware and software providers such as Amazon, for their voice enabled Alexa devices and platform. Some of our most innovative use cases of the audico technology platform has been deployed working on the Alexa foundation, of which we received significant press, coverage, exposure and funding from various Amazon entities. My own personal story behind building and implementing our solution across Hospitality and Healthcare was featured in the Amazon Alexa Innovators newsletter for my consistent and forward thinking work with voice AI.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7077547468489379840",
    linkText: "Let's see the newsletter!",
    figure: null,
    figureText: null,
    tags: [
      ["AI", "warning"],
      ["Innovation", "info"],
      ["Newsletter", "dark"],
    ],
    showOnHome: true,
  },
  {
    id: "guest-lecturer",
    route: ["Academia", "Lecturing"],
    backgroundIcon: AcademicCapIcon,
    backgroundIconColour: "info",
    title: "Visiting Lecturer",
    shortBody: "Invited to be a visiting lecturer at Loughborough, Brunel and Surrey Universities. I delivered lectures for Data Science & business BSc & MSc courses as well as interactive hands-on workshops.",
    longBody: "I have always found Universities to be an incredible hub for innovation and inspiration. I took a proactive approach to continue collaborating with academia after graduating, of which I was able to build strong relationships with Loughborough, Brunel & Surrey Universities faculty members across Business, Computer Science, AI & Data Science. As part of this ongoing relationship, I was invited to be a visiting lecturer and delivered lectures for Data Science & Business BSc & MSc courses. I was also invited to create and host interactive hands-on workshops as a form of \"Industrial collaboration\".",
    entity: "Halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Teaching", "danger"],
      ["Lecturing", "primary"],
      ["Leadership", "warning"],
    ],
    showOnHome: true,
  },
  {
    id: "audico-research-uos",
    route: ["Academia", "Research"],
    backgroundIcon: AcademicCapIcon,
    backgroundIconColour: "info",
    title: "Voice AI Research",
    shortBody: "Collaborated with University of Surrey to publish 2 research papers on the topic of AI within Hospitality and the feasibility of AI voice assistants in hotels.",
    longBody: "As part of our innovation agenda at audico, we collaborate closely with academic institutions such as the University of Surrey to push the boundaries of AI and voice technology. We have published 2 research papers on the topic of AI within Hospitality and the feasibility of AI voice assistants in hotels, working with the world renowned \"Surrey Centre for Hospitality & Tourism\".",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Leadership", "warning"],
      ["Collaboration", "dark"],
      ["AI", "success"],
    ],
    showOnHome: true,
  },
  {
    id: "jrme-sna",
    route: ["Academia", "Research"],
    backgroundIcon: AcademicCapIcon,
    backgroundIconColour: "dark",
    title: "Research Publication",
    shortBody: "Published a research paper with fellow Professors on Social Network Analysis in the Journal for Research in Mathematics Education.",
    longBody: "During my undergraduate studies I embarked on a research project to use a statistical methodology & algorithm called \"Social Network Analysis\" to understand the learning dynamics between a cohort of over 200 first year Mathematics students. The goal was to identify learning patterns, potential brokerage opportunities and to understand how this methodology could be used to model behaviours. The research was a great success, I was invited to speak about my research at several conferences and continued to collaborate with my two Professors to publish this paper in the Journal for Research in Mathematics Education. In addition to the first publication, I contributed to a second publication with both Professors after graduating.",
    entity: "Loughborough University",
    entityLogo: "img/graphics/entity/lboro.svg",
    link: "https://www.researchgate.net/publication/338353317_Study_Habits_and_Attainment_in_Undergraduate_Mathematics_A_Social_Network_Analysis",
    linkText: "Read the paper here!",
    figure: null,
    figureText: null,
    tags: [
      ["Mathematics", "primary"],
      ["Modeling", "info"],
      ["Social Network Analysis", "warning"],
    ],
    showOnHome: true,
  },
  {
    id: "uos-eoy",
    route: ["Academia", "Entrepreneurship", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Entrepreneur of the Year",
    shortBody: "Awarded Entrepreneur of the Year at the University of Surrey Student Enterprise Hub.",
    longBody: "During my time at the University of Surrey I was an active member of the Student Enterprise hub. The hub was not only a place that helped me to launch various businesses, but it was a mechanism for me to help other businesses to validate & launch too. Through hard work and early success from my own businesses and helping 5 other businesses to launch their applications (ranging from VR, to AI, to Mental Health) I was given the  \"Entrepreneur of the Year\" award.",
    entity: "University of Surrey",
    entityLogo: "img/graphics/entity/uos.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Business", "primary"],
      ["Collaboration", "warning"],
      ["Innovation", "info"],
    ],
    showOnHome: true,
  },
  {
    id: "uos-santander-foundership",
    route: ["Academia", "Entrepreneurship", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Santander Foundership",
    shortBody: "Awarded a Foundership from Santander to support and fund the development of \"MyHudson\", later known as audico. Received further funding through the \"Cube Fund\" once again from Santander.",
    longBody: "Pitched for investment from Santander for the development of the early stage \"MyHudson\" system, later known as audico. I was successful with the pitch and was awarded a Foundership from Santander including funding and access to business mentorship. After this first round of funding, I pitched for and received a second round of funding from Santander through the \"Cube Fund\".",
    entity: "University of Surrey",
    entityLogo: "img/graphics/entity/uos.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Business", "primary"],
      ["Funding", "warning"],
      ["Mentorship", "danger"],
    ],
    showOnHome: true,
  },
  {
    id: "audico-santander-x",
    route: ["Academia", "Entrepreneurship", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Santander X",
    shortBody: "Over 50,000 startups applied but we were successfully nominated to compete in the Santandar X startup award for audico.",
    longBody: "I was invited to apply to the Santander X competition for university alumni startups, based on the success and progress of audico. Over 50,000 startups applied but we were successfully nominated to compete in the Santandar X startup award and took part in the pitching competition based the The Oval in London.",
    entity: "audico",
    entityLogo: "img/graphics/entity/audico.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Startup", "primary"],
      ["Progress", "success"],
    ],
    showOnHome: true,
  },
  {
    id: "sap-gps",
    route: ["Career", "SAP", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Gifted Public Speaker",
    shortBody: "Awarded title of \"Gifted Public Speaker\" for my internal, external and enablement presentations at SAP.",
    longBody: "A large responsibility of mine within the Presales team was to display and demonstrate \"Thought Leadership\" and \"Trusted Advisory\" on Data Science & Machine Learning topics through public speaking opportunities. These included: speaking at conferences such as \"London Tech Week\", special interest groups and customer workshops to name a few. Using creative ways to simplify and make sense of complex topics, I was awarded the title of \"Gifted Public Speaker\" from SAP for my innovative methods of making technical topics accessible to all.",
    entity: "SAP",
    entityLogo: "img/graphics/entity/sap.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Public Speaking", "info"],
      ["Presentation", "danger"],
      ["Thought Leadership", "dark"],
    ],
    showOnHome: true,
  },
  {
    id: "sap-1",
    route: ["Career", "SAP", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Ranked #1 in Global Talent Academy",
    shortBody: "I was ranked #1 in the global talent cohort resulting in an extended secondment in San Francisco to teach proceeding batches of talent.",
    longBody: "The \"Global Early Talent Academy\" was a training program designed to equip talent from different industries to apply Presales excellence with multinational business acumen, soft skills and technical training to excel in their market unit. This included team based activities, independent demos & presentations, Capstone assessments and technical examinations - all of which were graded and scored. Every aspect of our daily working lives were monitored, such as teamwork, collaboration & positive attitude. At the end of the 6 intense months in Silicon Valley,  I was ranked #1 in the global cohort resulting in an extended secondment in San Francisco to teach and mentor proceeding batches of talent.",
    entity: "SAP",
    entityLogo: "img/graphics/entity/sap.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Attitude", "primary"],
      ["Collaboration", "warning"],
      ["Performance", "info"],
    ],
    showOnHome: true,
  },
  {
    id: "sap-btp",
    route: ["Career", "SAP", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Best Team Player",
    shortBody: "Awarded title of \"Best Team Player\" from colleagues and managers, demonstrating my collaborative and inclusive skills with people.",
    longBody: "During my time in Silicon Valley at the \"Global Early Talent Academy\" we were required to work with customers, internal teams and colleagues to complete weekly assessments in the form of examinations and presentations. Given that we were a cohort of talent from all across the world, there was a big emphasis on collaboration and teamwork to help each other succeed. I was awarded the title of \"Best Team Player\" from colleagues and managers, highlighting my commitment to help others through challenging times and not leave anyone behind.",
    entity: "SAP",
    entityLogo: "img/graphics/entity/sap.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Teamwork", "primary"],
      ["Collaboration", "warning"],
      ["People", "success"],
    ],
    showOnHome: true,
  },
  {
    id: "sap-l4",
    route: ["Career", "SAP", "Certification"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "Predictive Analytics Certification",
    shortBody: "Achieved highest level of certification in Predictive Analytics at SAP & being the only person in the UK to hold this accolade at the time.",
    longBody: "When I started at SAP on my placement year in 2014 I joined at a time when the term \"Data Science\" had only just been coined, and \"Machine Learning\" was a term that had never been heard of, rather \"Predictive Analytics\" was the common buzzword. With my degree background being Mathematics, I was brought in to provide expertise and knowledge around how proprietary and open source Predictive Analytics technology worked at Enterprise level. During my 13 months on placement, I acquired valuable soft and technical skills including achieving the highest formal level of Predictive Analytics certificaton within SAP at the time (L4). Additionally, I was the only person in the UK to hold this accolade at the time.",
    entity: "SAP",
    entityLogo: "img/graphics/entity/sap.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Predictive Analytics", "info"],
      ["Certification", "dark"],
    ],
    showOnHome: true,
  },
  {
    id: "sap-blogs",
    route: ["Career", "SAP", "Content"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "SAP Blogs",
    shortBody: "Received over 30,000 views on my SAP blogs focusing on my contribution to the Python open source Machine Learning package that I was developing.",
    longBody: "Since the development of the open source Python package was a new innovation from SAP, as part of my tenure with the HANA ML development team I wrote a series of blog posts to support the documentation that we released and to provide hands-on examples that users could follow to start using the package. My blogs received ~30,000 views, ranging from topics such as working with DataFrames, Exploratory Data Analysis, and the Machine Learning library.",
    entity: "SAP",
    entityLogo: "img/graphics/entity/sap.svg",
    link: "https://community.sap.com/t5/user/viewprofilepage/user-id/224878",
    linkText: "Read them here!",
    figure: null,
    figureText: null,
    tags: [
      ["Content", "danger"],
      ["Machine Learning", "warning"],
      ["Open Source", "dark"],
    ],
    showOnHome: true,
  },
  {
    id: "uos-5g",
    route: ["Academia", "Entrepreneurship", "Award"],
    backgroundIcon: HeartIcon,
    backgroundIconColour: "warning",
    title: "5G Centre Hackathon",
    shortBody: "Won the 5G Centre Hackathon at the University of Surrey for building an intelligent fire alarm system within 3 hours in C++.",
    longBody: "Won the 5G Centre Hackathon at the University of Surrey for building an intelligent fire alarm system within 3 hours in C++. We were given a brief to design a fire alarm system that could detect when temperatures rose above a certain critical threshold. When this occured, it had to send real-time messages to a message bus using an intelligent queueing system. I was able to successfully build the system within the allocated time and present it to the judges.",
    entity: "University of Surrey",
    entityLogo: "img/graphics/entity/uos.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["IoT", "dark"],
      ["Software Engineering", "info"],
    ],
    showOnHome: true,
  },
  {
    id: "uos-3ds",
    route: ["Academia", "Entrepreneurship", "Award"],
    backgroundIcon: BriefcaseIcon,
    backgroundIconColour: "dark",
    title: "3DS Hackathon Winner",
    shortBody: "Designed, pitched and developed a business idea focused on sustainability in 3 days and won the competition amongst 100+ SetSquared students.",
    longBody: "Designed, pitched and developed a business idea focused on sustainability in 3 days and won the competition amongst 100+ SetSquared students. The first day consisted of pitching my idea for sustainability to the entire cohort, of which mine was selected as 1 of 5 to move forward. Next, the remaining participants joined teams with the creator of the idea that they resonated with most and worked on building this idea for the remaining 2.5 days. I led the team in designing a pitch, proving business viability by visiting neighbouring towns and interviewing potential customers, and developing a prototype of the product. The final day consisted of pitching the idea to a panel of judges and receiving feedback. It was an intense and fun time! I love working dynamically and solving problems with great people!",
    entity: "University of Surrey",
    entityLogo: "img/graphics/entity/uos.svg",
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Hackathon", "success"],
      ["Startups", "info"],
    ],
    showOnHome: true,
  },
  {
    id: "kaggle-content",
    route: ["Personal", "Content", "Data Science"],
    backgroundIcon: PresentationChartLineIcon,
    backgroundIconColour: "danger",
    title: "Kaggle Contributor & Medals",
    shortBody: "Awarded gold medals for my content contributions to the Kaggle community, receiving over 1000 forks of my notebooks.",
    longBody: "My journey into the world of Data Science started with a lot of experimentation, hunger to work projects and desire to hone my technical skills. This drew me toward the \"Kaggle\" platform and allowed me to practice, build a community and satiate my passion for helping others to learn too! During my active time on the platform, I achieved one \"Top 5%\" award, one \"Top 10% award\" and \"Gold medals\" for my content contributions to the Kaggle community, receiving over 1000 forks of my notebooks.",
    entity: "Halo",
    entityLogo: "img/graphics/entity/halo.svg",
    link: "https://www.kaggle.com/agodwinp",
    linkText: "See my profile here!",
    figure: null,
    figureText: null,
    tags: [
      ["Content", "danger"],
      ["Community", "primary"],
      ["Data Science", "success"],
    ],
    showOnHome: true,
  },
  {
    id: "football-academy",
    route: ["Personal", "Sport"],
    backgroundIcon: HeartIcon,
    backgroundIconColour: "warning",
    title: "Football Academy",
    shortBody: "Through hard work and dedication I was part of the Fulham FC & Chelsea FC football academy between the ages of 12-14.",
    longBody: "Started playing football at the age of 9 and by the age of 10 was playing several years above my age before entering the Fulham FC Centre of Excellence. After graduating from the CoE, between the ages of 12-14, I spent 1 year at Fulham FC academy before spending a further 9 months at Chelsea FC academy. My time within these two environments exposed me to elite level sport and the dedication required to succeed at a young age.",
    entity: null,
    entityLogo: null,
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Sport", "dark"],
      ["Determination", "danger"],
      ["Teamwork", "primary"],
    ],
    showOnHome: true,
  },
  {
    id: "youth-athletics",
    route: ["Personal", "Sport"],
    backgroundIcon: HeartIcon,
    backgroundIconColour: "warning",
    title: "Athletic Achievements",
    shortBody: "Represented and competed for Surrey County at various atheletic meets in the 100m sprint, long jump & 4 X 100m relay.",
    longBody: "Represented and competed for Surrey county at various athletic meets across South England in the 100m sprint, long jump & 4 X 100m relay. Held the record for long jump at under 15 level with a jump of 6.2m.",
    entity: null,
    entityLogo: null,
    link: null,
    linkText: null,
    figure: null,
    figureText: null,
    tags: [
      ["Sport", "dark"],
      ["Determination", "danger"],
      ["Teamwork", "primary"],
    ],
    showOnHome: true,
  },
]