import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";

// Redux
import { store } from "./store";
import NotFound from "./components/routing/NotFound";
import Landing from "./components/landing/Landing";
import Home from "./components/home/Home";


function App() {

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Landing - Public */}
          <Route path="/" element={<Landing />} />

          {/* Resumé - Public */}
          <Route path="/resume" element={<Home />} />

          {/* Not Found */}
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
