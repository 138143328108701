import { useState } from "react";
import Card from "../layout/Card";
import { sendSesEmail } from "../../utils/email";
import { useAppDispatch } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";


export default function ContactForm() {
  const dispatch = useAppDispatch();

  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    message: "",
  })

  async function handleSubmit(e: any) {
    e.preventDefault();
    const { firstName, lastName, company, email, message } = contactData;

    // Validate required fields
    if (firstName === "") {
      dispatch(addAlert("Please fill out your first name!", "danger", 3000));
      return;
    }
    if (lastName === "") {
      dispatch(addAlert("Please fill out your last name!", "danger", 3000));
      return;
    }
    if (email === "") {
      dispatch(addAlert("Please fill out your email!", "danger", 3000));
      return;
    }
    if (message === "") {
      dispatch(addAlert("Please fill out your message!", "danger", 3000));
      return;
    }

    // Send request
    const res: any = await sendSesEmail(firstName, lastName, company === "" ? null : company, email, message);
    const statusCode = res.$metadata.httpStatusCode

    // Display alert
    if (statusCode === 200) {
      dispatch(addAlert("Message sent successfully!", "success", 3000));
      setContactData({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        message: "",
      });
    } else {
      dispatch(addAlert("Message failed to send, please try again later!", "danger", 3000));
    }
  }

  function redirectToEmail(e: any) {
    e.preventDefault();
    if (typeof window !== "undefined" && window !== null) {
      const newWindow = window.open("mailto:arun@halotechlab.com", '_blank');
      if (newWindow !== null) {
        newWindow.focus();
      }
    }
  }

  return (
    <section className="border-t border-dark/20 pt-20" aria-labelledby="section-9-title" id="contact">
      <h2 id="section-9-title" className="sr-only">Contact</h2>
      <div className="grid grid-cols-1 gap-4">

        {/* Header */}
        <div className="text-center mb-4">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-extrabold leading-7 text-dark mb-4">Get in touch!</h2>
          <p className="text-sm font-light leading-normal text-dark">
            If you have any questions or would like to discuss a project, please feel free to get in touch!
            {/* If you have any questions or would like to discuss a project, please feel free to get in touch using the form below. */}
          </p>
        </div>

        {/* Form */}
        <div className="mx-auto sm:max-w-xl w-full mt-10">
          {/* <Card>
            <form>
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-semibold leading-6 text-dark"
                  >
                    First name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-dark shadow-sm ring-2 ring-inset ring-dark placeholder:text-light placeholder:font-light focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 transition ease-in-out delay-100 duration-300 hover:scale-105 hover:bg-beige"
                      required={true}
                      value={contactData.firstName}
                      placeholder="What's your first name?"
                      onChange={(e) => setContactData({ ...contactData, firstName: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-semibold leading-6 text-dark"
                  >
                    Last name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-dark shadow-sm ring-2 ring-inset ring-dark placeholder:text-light placeholder:font-light focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 transition ease-in-out delay-100 duration-300 hover:scale-105 hover:bg-beige"
                      required
                      value={contactData.lastName}
                      placeholder="What's your last name?"
                      onChange={(e) => setContactData({ ...contactData, lastName: e.target.value })}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-semibold leading-6 text-dark"
                  >
                    Company
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="organization"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-dark shadow-sm ring-2 ring-inset ring-dark placeholder:text-light placeholder:font-light focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 transition ease-in-out delay-100 duration-300 hover:scale-105 hover:bg-beige"
                      value={contactData.company}
                      placeholder="What's your company?"
                      onChange={(e) => setContactData({ ...contactData, company: e.target.value })}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-dark"
                  >
                    Email
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-dark shadow-sm ring-2 ring-inset ring-dark placeholder:text-light placeholder:font-light focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 transition ease-in-out delay-100 duration-300 hover:scale-105 hover:bg-beige"
                      required
                      value={contactData.email}
                      placeholder="What's your email?"
                      onChange={(e) => setContactData({ ...contactData, email: e.target.value })}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-dark"
                  >
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-dark shadow-sm ring-2 ring-inset ring-dark placeholder:text-light placeholder:font-light focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 transition ease-in-out delay-100 duration-300 hover:scale-105 hover:bg-beige"
                      required
                      value={contactData.message}
                      placeholder="What would you like to say?"
                      onChange={(e) => setContactData({ ...contactData, message: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <button
                  type="submit"
                  className="block w-full rounded-md bg-gradient-to-r from-primary via-primary-light to-success background-animate px-3.5 py-2.5 text-center text-sm font-semibold text-white hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-primary-dark hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-dark"
                  onClick={(e) => handleSubmit(e)}
                >
                  Let's talk!
                </button>
              </div>
            </form>
          </Card> */}
          <a className="group" href="#!" onClick={(e: any) => redirectToEmail(e)} rel="noreferrer">
            <Card className="transition ease-in-out delay-100 duration-300 hover:scale-105">
              <span className="flex justify-between text-dark">
                <PaperAirplaneIcon className="h-6 w-6 text-dark" />
                Click here to send me an email!
              </span>
            </Card>
          </a>
        </div>
      </div>
    </section>
  );
}
