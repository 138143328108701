import Dendrogram from "../charts/hierarchy/Dendrogram";
import Card from "../layout/Card";

export default function Recognition() {

  return (
    <section aria-labelledby="section-8-title" id="recognition">
      <h2 id="section-8-title" className="sr-only">Recognition</h2>
      <div className="grid grid-cols-1 gap-4">

        <Card className="grid grid-cols-1 gap-4">
          <div className="text-left">

            {/* Header */}
            <h2 className="text-xl font-medium leading-7 text-dark mb-4">Recognition</h2>
            <p className="font-light text-sm leading-6 text-dark">
              This tree contains a collection of instances where I received stellar recognition. Navigate through the tree and click on the "nodes" to reveal hints on how to find more details.
            </p>

            {/* Chart */}
            <Dendrogram />
          </div>
        </Card>

      </div>
    </section>
  )
}
