import { AcademicCapIcon, CodeBracketSquareIcon, CpuChipIcon, PresentationChartLineIcon } from "@heroicons/react/24/solid"

const timeline = [
  {
    icon: AcademicCapIcon,
    name: 'BSc Mathematics',
    description: 'Graduated with a First class Honours and completed a placement year from Loughborough University with 82% overall.',
    date: '2016',
    dateTime: '2016',
  },
  {
    icon: PresentationChartLineIcon,
    name: 'MIT Data Science',
    description:
      'Completed Massachusetts Institute of Technology Data Science Professional Certification with a score of 100%.',
    date: '2017',
    dateTime: '2017',
  },
  {
    icon: CodeBracketSquareIcon,
    name: 'Full Stack Developer Nanodegree',
    description: "Completed the Full Stack Developer Nanodegree from Udacity with a score of 100% and a distinction.",
    date: '2019',
    dateTime: '2019',
  },
  {
    icon: CpuChipIcon,
    name: 'MSc Computer Vision, Robotics & Machine Learning',
    description: "Graduated with Distinction from the University of Surrey's Centre for Vision, Speech & Signal Processing achieving 92% overall.",
    date: '2020',
    dateTime: '2020',
  },
]

export default function Timeline() {
  return (
    <div className="">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
        {timeline.map((item) => (
          <div key={item.name} className="group">
            <time
              dateTime={item.dateTime}
              className="flex items-center text-lg font-semibold leading-6 text-dark"
            >
              <svg viewBox="0 0 4 4" aria-hidden="true" className="mr-4 h-1 w-1 flex-none group-hover:text-info">
                <circle r={2} cx={2} cy={2} fill="currentColor" />
              </svg>
              <span className="flex">
                <div className="h-10 w-10 mr-2 flex items-center justify-center rounded-lg bg-gradient-to-r from-transparent to-transparent group-hover:from-info group-hover:to-info-light">
                  <item.icon aria-hidden="true" className="h-6 w-6 text-dark group-hover:text-white mx-auto my-auto" />
                </div>
                <span className="my-auto group-hover:text-info">
                  {item.date}
                </span>
              </span>
              <div
                aria-hidden="true"
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-4 lg:w-auto lg:flex-auto lg:translate-x-0"
              />
            </time>
            <p className="mt-4 text-medium font-medium leading-8 tracking-tight text-dark group-hover:text-info">{item.name}</p>
            <p className="mt-1 text-small font-light leading-7 text-dark">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
