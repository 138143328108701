import { useState } from "react";
import Card from "../layout/Card";
import { ArrowUpCircleIcon, BeakerIcon, BuildingOffice2Icon, CodeBracketSquareIcon, CreditCardIcon, CubeIcon, FaceSmileIcon, HeartIcon, LinkIcon, SparklesIcon, SunIcon } from "@heroicons/react/24/solid";
import { featuredStory, stories, Story } from "../../utils/data";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Stories() {

  // Component level state
  const [activeFilter, setActiveFilter] = useState({ name: "", category: "" });

  // Constant variables
  const filters = [
    {
      name: "AI",
      icon: SparklesIcon,
      category: "ai"
    },
    {
      name: "Data Science",
      icon: BeakerIcon,
      category: "data-science"
    },
    {
      name: "VR",
      icon: CubeIcon,
      category: "vr"
    },
    {
      name: "Software Engineering",
      icon: CodeBracketSquareIcon,
      category: "software-engineering"
    },
    {
      name: "Mental health",
      icon: FaceSmileIcon,
      category: "mental-health"
    },
    {
      name: "Healthcare",
      icon: HeartIcon,
      category: "healthcare"
    },
    {
      name: "Sustainability",
      icon: SunIcon,
      category: "sustainability"
    },
    {
      name: "Payments",
      icon: CreditCardIcon,
      category: "payments"
    },
  ]

  const filteredStories: Story[] = stories.filter((story: Story) => activeFilter.name !== "" ? story.filter.includes(activeFilter.category) : story)
  let farLeft: Story[] = [];
  let middleLeft: Story[] = [];
  let middleRight: Story[] = [];
  let farRight: Story[] = [];
  let counter = 0;
  const counterThreshold = 3;
  filteredStories.forEach((story: Story) => {
    if (counter === 0) farLeft.push(story);
    if (counter === 1) middleLeft.push(story);
    if (counter === 2) middleRight.push(story);
    if (counter === 3) farRight.push(story);
    counter += 1
    if (counter > counterThreshold) counter = 0;
  })
  const formattedStories = [
    [farLeft, middleLeft],
    [middleRight, farRight]
  ]

  return (
    <section aria-labelledby="section-7-title" id="stories">
      <h2 id="section-7-title" className="sr-only">Stories</h2>
      <div className="grid grid-cols-1 gap-4">

        {/* Header */}
        <div className="text-left mb-4">
          <h2 className="text-xl font-medium leading-7 text-dark mb-4">Stories</h2>
          <p className="font-light text-sm leading-6 text-dark">
            Browse the most recent relevant stories from my end users, clients and references! Select a filter to view stories from a specific category.
          </p>
        </div>

        {/* Filters */}
        <div className="">
          <div className="grid grid-cols-1 items-center gap-8">
            <nav className="flex space-x-4 overflow-scroll scrollbar-invisible py-2 px-1">
              {filters.map((item) => (
                <button
                  key={item.name}
                  onClick={(e) => activeFilter.category === item.category ? setActiveFilter({ name: "", category: "" }) : setActiveFilter(item)}
                  className={`${activeFilter.name === item.name ? "text-white" : "text-dark"} flex rounded-md bg-dark bg-opacity-${activeFilter.name === item.name ? "10" : 0} px-3 py-2 text-sm font-medium ${activeFilter.name !== item.name && "hover:bg-opacity-10"} transition ease-in-out delay-100 duration-300 ${activeFilter.name === item.name && "scale-110"} hover:scale-110`}
                >
                  <item.icon className={`h-6 w-6 flex-shrink-0 self-center text-${activeFilter.name === item.name ? "white" : "dark"} mr-2`} />
                  <span className="my-auto">
                    {item.name}
                  </span>
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Stories content */}
        <div className="mx-auto">

          {/* Featured story */}
          <div className="grid grid-cols-1 mb-8">
            {activeFilter.name === "" || featuredStory.filter.includes(activeFilter.category) ? (
              // <a href={`/stories/${featuredStory.id}`} target="_blank" rel="noreferrer" className="sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <Card className="sm:col-span-2 xl:col-start-2 xl:row-end-1 transition ease-in-out delay-100 duration-300 hover:scale-105 hover:shadow">
                <figure className="grid grid-cols-1 gap-y-4">

                  {/* Title */}
                  <p className="text-dark text-base font-medium leading-6">{featuredStory.title}</p>

                  {/* Quote */}
                  <blockquote className="p-4 text-lg font-semibold leading-7 tracking-tight text-dark sm:text-xl sm:leading-8 border-s-8 border-primary bg-primary/20 ">
                    <p>{`“${featuredStory.quote}”`}</p>
                    <p className="text-sm text-dark italic font-light">{featuredStory.quoteSource}</p>
                  </blockquote>

                  {/* Body */}
                  <p className="text-sm text-secondary font-light">{featuredStory.body}</p>

                  {/* Footer */}
                  <figcaption className="flex flex-wrap items-center justify-between gap-x-4 gap-y-4 border-t border-dark/10 pt-4 sm:flex-nowrap">

                    {/* Entity */}
                    <span className="flex">
                      <BuildingOffice2Icon className="h-6 w-6 flex-none text-dark mr-2" />
                      <span className="font-semibold">{featuredStory.company}</span>
                    </span>

                    {/* Link for more */}
                    {featuredStory.link && featuredStory.linkText && (
                      <a href={featuredStory.link} target="_blank" rel="noreferrer" className="flex group text-dark">
                        <LinkIcon className="h-6 w-6 flex-none group-hover:text-primary mr-2" />
                        <span className="font-semibold group-hover:text-primary group-hover:underline">
                          {featuredStory.linkText}
                        </span>
                      </a>
                    )}
                  </figcaption>
                </figure>
              </Card>
              // </a>
            ) : null}
          </div>

          <div className="mx-auto mt-4 grid grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-dark sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">

            {/* Other stories */}
            {formattedStories.map((columnGroup: Array<Array<Story>>, columnGroupIdx: number) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column: Array<Story>, columnIdx: number) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === stories.length - 1 && columnIdx === columnGroup.length - 1)
                        ? 'xl:row-span-2'
                        : 'xl:row-start-1',
                      'space-y-8',
                    )}
                  >
                    {column.filter((item: Story) => activeFilter.name !== "" ? item.filter.includes(activeFilter.category) : item).map((story: Story, itemIdx: number) => {
                      return (
                        // <a key={story.id} href={`/stories/${story.id}`} target="_blank" rel="noreferrer">
                        <Card className={`transition ease-in-out delay-100 duration-300 hover:scale-105 hover:shadow mb-8`}>
                          <figure className="grid grid-cols-1 gap-y-4">

                            {/* Title */}
                            <p className="text-dark text-base font-medium leading-6">{story.title}</p>

                            {/* Quote */}
                            {story.quote && story.quoteSource && (
                              <blockquote className="p-4 text-sm font-medium tracking-tight text-dark sm:text-md border-s-8 border-light bg-light/20 ">
                                <p>{`“${story.quote}”`}</p>
                                <p className="text-xs text-dark italic font-light">{story.quoteSource}</p>
                              </blockquote>
                            )}

                            {/* Body */}
                            <p className="text-sm text-secondary font-light">{story.body}</p>

                            {/* Figure */}
                            {story.figure && (
                              <figcaption className="items-center justify-between gap-x-4 gap-y-4 border-t border-dark/10 pt-4 sm:flex-nowrap">
                                <span className="text-lg md:text-xl font-semibold text-dark">{story.figure}</span>
                                <span className="font-light text-dark">{" "}{story.figureText}</span>
                              </figcaption>
                            )}

                            {/* Footer */}
                            <figcaption className="flex flex-wrap items-center justify-between gap-x-4 gap-y-4 border-t border-dark/10 pt-4 sm:flex-nowrap">

                              {/* Entity */}
                              <span className="flex">
                                <BuildingOffice2Icon className="h-6 w-6 flex-none text-dark mr-2" />
                                <span className="font-semibold">{story.company}</span>
                              </span>

                              {/* Link for more */}
                              {story.link && story.linkText && (
                                <a href={story.link} target="_blank" rel="noreferrer" className="flex group text-dark">
                                  <LinkIcon className="h-6 w-6 flex-none group-hover:text-primary mr-2" />
                                  <span className="font-semibold group-hover:text-primary group-hover:underline">
                                    {story.linkText}
                                  </span>
                                </a>
                              )}
                            </figcaption>
                          </figure>
                        </Card>
                        // </a>
                      )
                    })}
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/* Go back to filters button */}
          <div className="grid grid-cols-1 gap-y-6 text-center w-10/12 md:w-1/2 lg:w-1/3 mx-auto mt-8">
            <a
              className="group relative w-full justify-center rounded-full border border-2 border-dark bg-transparent py-2 px-4 text-sm font-medium text-dark hover:bg-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition ease-in-out delay-100 duration-300 hover:scale-125"
              href="#stories"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <ArrowUpCircleIcon
                  className="hidden sm:block h-5 w-5 fill-dark"
                  aria-hidden="true"
                />
              </span>
              Go back to Filters
            </a>
          </div>

        </div>

      </div>
    </section>
  )
}
