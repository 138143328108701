import { experienceData } from "../../utils/data";
import StackedList from "../layout/StackedList";


export default function Experience() {

  return (
    <section aria-labelledby="section-4-title" id="experience">
      <h2 id="section-4-title" className="sr-only">Experience</h2>
      <div className="grid grid-cols-1 gap-4">

        {/* Header */}
        <div className="text-left mb-4">
          <h2 className="text-xl font-medium leading-7 text-dark mb-4">Experience</h2>
          <p className="text-sm font-light leading-normal text-dark">
            By pursuing a career in the fascinating area of Data Science & AI, I've had the opportunity to work with some amazing companies, people & projects. Here are some of the highlights.
          </p>
        </div>
        <StackedList
          data={experienceData}
        />
      </div>
    </section>
  );
}
