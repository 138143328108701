import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

export default function Landing() {
  return (
    <div className="h-screen bg-gradient-to-r from-primary via-primary-light to-success background-animate flex items-center justify-center">
      <div className="grid grid-cols-1 gap-y-6 text-center w-10/12 md:w-1/2 lg:w-1/3">

        {/* Hi */}
        <p className="text-4xl md:text-6xl lg:text-7xl font-extrabold text-dark">Hi! 👋🏼</p>

        {/* Intro */}
        <p className="text-2xl md:text-4xl lg:text-5xl font-bold text-dark">I'm Arun</p>

        {/* CTA */}
        <a
          className="group relative w-full justify-center rounded-full border border-2 border-dark bg-white py-2 px-4 text-sm font-medium text-dark hover:bg-beige focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition ease-in-out delay-100 duration-300 hover:scale-125"
          href="/resume"
        >
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <ArrowRightCircleIcon
              className="hidden sm:block h-5 w-5 fill-dark"
              aria-hidden="true"
            />
          </span>
          Find out more!
        </a>
      </div>
    </div>
  );
}
